





















































































































































import Vue from 'vue';
import PlainButton from '@/components/shared/PlainButton.vue';

export default Vue.extend({
  components: {
    PlainButton
  },
  mounted() {
    this.scrollTrigger('.home-prices-scroll-reveal');
  },
  methods: {
    scrollTrigger(selector) {
      const els = document.querySelectorAll(selector);
      const elsArray = Array.from(els);
      elsArray.forEach(el => {
        this.addObserver(el);
      });
    },
    addObserver(el) {
      // We are creating a new IntersectionObserver instance
      const observer = new IntersectionObserver(
        (entries, observer) => {
          // This takes a callback function that receives two arguments: the elements list and the observer instance.
          entries.forEach(entry => {
            // `entry.isIntersecting` will be true if the element is visible
            if (entry.isIntersecting) {
              document
                .getElementById('home-prices-card')
                .classList.remove('lg:tw-translate-x-full');
              document
                .getElementById('home-prices-card')
                .classList.add('lg:wz-translate-x-0');
              document
                .getElementById('home-prices-wave')
                .classList.remove('lg:wz-translate-y-100');
              document
                .getElementById('home-prices-wave-md')
                .classList.remove('wz-translate-y-100');
              // We are removing the observer from the element after adding the active class
              observer.unobserve(el);
            }
          });
        },
        { threshold: 0.5 }
      );
      // Adding the observer to the element
      observer.observe(el);
    }
  }
});
