







import Vue from 'vue';

export default Vue.extend({
  props: {
    color: {
      required: true,
      type: String
    }
  }
});
