import apiService from './api.service';

export const EMPLOYEES_LIST_LIMIT = 25;

export default {
  async getAll() {
    const response = await apiService.get('employees/all.json');
    return response.data.data;
  },
  async getById(id) {
    const response = await apiService.get(`employees/${id}.json`);
    return response.data.data;
  },
  async add(employee) {
    const response = await apiService.post('employees.json', employee);
    return response.data.data;
  },
  async edit(employee) {
    const response = await apiService.put(
      `employees/${employee.id}.json`,
      employee
    );
    return response.data.data;
  },
  async verifyAddTimetable(timetable) {
    const response = await apiService.post(
      'timetables/verify-add.json',
      timetable
    );
    return response.data.data;
  },
  async verifyAddTimetableDateException(timetableDateException) {
    const response = await apiService.post(
      'timetable-date-exceptions/verify-add.json',
      timetableDateException
    );
    return response.data.data;
  },
  async verifyDeleteTimetableDateException(timetableDateException) {
    const response = await apiService.post(
      'timetable-date-exceptions/verify-delete.json',
      timetableDateException
    );
    return response.data.data;
  },
  async saveTimetable(timetable) {
    const response = await apiService.post('timetables.json', timetable);
    return response.data.data;
  },
  async verifyDeleteTimetable(timetableId) {
    const response = await apiService.get(
      `timetables/verify-delete/${timetableId}.json`
    );
    return response.data.data;
  },
  async deleteTimetable(timetableId) {
    const response = await apiService.delete(`timetables/${timetableId}.json`);
    return response.data.data;
  },
  async getTimetables(employeeId, page = 1, limit = 25) {
    const response = await apiService.get(
      `timetables.json?employee_id=${employeeId}`,
      { page, limit }
    );
    return {
      timetables: response.data.data,
      pagination: response.data.pagination
    };
  },
  async findOneWeekday(date, employeeId) {
    const response = await apiService.get(
      `timetables/weekday/${date}/${employeeId}.json`
    );
    return response.data.data;
  },
  async getSchedulesBetweenDates(dateFrom, dateTo, employeeId) {
    const response = await apiService.get(
      `timetables/schedules-between-dates/${dateFrom}/${dateTo}.json`,
      {
        employee_id: employeeId || undefined,
        format: 'api'
      }
    );
    return response.data.data;
  },
  async findOneTimetableDateException(date, employeeId) {
    const response = await apiService.get(
      `timetable-date-exceptions/${date}/${employeeId}.json`
    );
    return response.data.data;
  },
  async getTimetableDateExceptions(
    employeeId,
    page = 1,
    past = false,
    limit = EMPLOYEES_LIST_LIMIT
  ) {
    const response = await apiService.get(`timetable-date-exceptions.json`, {
      employee_id: employeeId,
      page,
      limit,
      past
    });
    return {
      exceptions: response.data.data,
      pagination: response.data.pagination
    };
  },
  async addTimetableDateException(dateException) {
    const response = await apiService.post(
      'timetable-date-exceptions.json',
      dateException
    );
    return response.data.data;
  },
  async deleteTimetableDateException(employeeId, date) {
    const response = await apiService.post(
      'timetable-date-exceptions/delete.json',
      {
        employeeId: employeeId,
        date: date
      }
    );
    return response.data.data;
  },
  async addEvent(event) {
    const response = await apiService.post('events.json', event);
    return response.data.data;
  },
  async getEventsBetweenDates(employeeId, dateFrom, dateTo) {
    const response = await apiService.get(
      `events/between-dates/${dateFrom}.json?date_to=${dateTo}&include_associations=true&employee_id=${employeeId}`
    );
    return response.data.data;
  },
  async deleteEvent(eventId) {
    const response = await apiService.delete(`events/${eventId}.json`);
    return response.data.data;
  },
  async setServicesForEmployee(employeeId, employeesServices) {
    const response = await apiService.post(
      `employees/set-services/${employeeId}.json`,
      employeesServices
    );
    return response.data.data;
  },
  async setWorkplacesForEmployee(employeeId, payload) {
    const response = await apiService.post(
      `employees/set-workplaces/${employeeId}.json`,
      payload
    );
    return response.data.data;
  },
  async verifyDeleteEmployee(employeeId) {
    const response = await apiService.post(
      `employees/verify-delete/${employeeId}.json`
    );
    return response.data.data;
  },
  async findICalUrl() {
    const response = await apiService.get('employees/ical-url');
    return response.data.data;
  }
};
