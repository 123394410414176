import { defineStore } from 'pinia';
import {
  EntitiesState,
  getInitialEntityState
} from '@/store/common/entities-state';
import { Timetable } from '@/model/timetable';
import { createEntityGetters } from '@/store/common/entities-getters';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import employeeService from '@/services/employee.service';
import { useEmployeesStore } from '@/store/employees-store';
import * as Sentry from '@sentry/vue';
import { useUiStore } from '@/store/ui-store';
import moment from 'moment';

const storeId = 'timetables';

export enum TimetableViewType {
  TIMETABLE = 'TIMETABLE',
  TIMETABLE_EXCEPTIONS = 'TIMETABLE_EXCEPTIONS'
}

function sortByValidFrom(a: Timetable, b: Timetable): number {
  return moment(a.valid_from).isAfter(moment(b.valid_from)) ? -1 : 1;
}
const initialEntityState = {
  ...getInitialEntityState<Timetable>(sortByValidFrom)
};
const state: EntitiesState<Timetable> & {
  viewType?: TimetableViewType;
} = initialEntityState;
state.viewType = TimetableViewType.TIMETABLE;

/**
 * Contient les timetables de l'employé sélectionné (défini dans employeesStore.currentEntity.id)
 */
export const useTimetablesStore = defineStore('timetables', {
  state: () => state,
  getters: {
    ...createEntityGetters<Timetable>()
  },
  actions: {
    ...createEntityActions<Timetable>(
      Collection.TIMETABLES,
      initialEntityState
    ),
    setViewType(viewType: TimetableViewType) {
      this.viewType = viewType;
    },
    setSelectedEmployeeWithTimetableIfCurrentHasNot(currentEmployeeId: number) {
      const employeesStore = useEmployeesStore();
      if (!employeesStore.currentEntity?.timetable) {
        employeesStore.currentEntity =
          employeesStore
            .getAllWithTimetablesEnabled()
            .find(employee => employee.id === currentEmployeeId) ??
          employeesStore.getAllWithTimetablesEnabled()[0];
      }
    },
    async findAll(
      employeeId: number,
      force: boolean = true,
      loader: boolean = true
    ) {
      const uiStore = useUiStore();
      try {
        if (this.entities.length === 0 || force) {
          if (loader) {
            this.fetching = true;
          }
          const response = await employeeService.getTimetables(employeeId);
          this.entities = response.timetables;
          this.fetching = false;
        }
      } catch (exception) {
        console.error(exception);
        this.failure = true;
        this.fetching = false;
        uiStore.alert(exception.message);
        Sentry.captureException(exception);
      }
    },
    async findAllPaginated(
      employeeId: number,
      payload?: {
        limit?: number;
        page?: number;
        force?: boolean;
      }
    ) {
      const uiStore = useUiStore();
      try {
        if (this.entities.length === 0 || payload?.force) {
          this.fetching = true;
          const response = await employeeService.getTimetables(
            employeeId,
            payload?.page,
            payload?.limit
          );
          this.entities = response.timetables;
          this.pagination = response.pagination;
          this.fetching = false;
        }
      } catch (exception) {
        console.error(exception);
        this.failure = true;
        this.fetching = false;
        uiStore.alert(exception.message);
        Sentry.captureException(exception);
      }
    }
  }
});
