
























































































































import Vue from 'vue';
import PlainButton from '@/components/shared/PlainButton.vue';
import HomeCard from '@/components/public/HomeCard.vue';
import Weeezy from '@/components/public/Weeezy.vue';
import HomeTitleHoverLine from '@/components/public/HomeTitleHoverLine.vue';

export default Vue.extend({
  components: {
    PlainButton,
    HomeCard,
    Weeezy,
    HomeTitleHoverLine
  },
  mounted() {
    this.scrollTrigger();
  },
  methods: {
    scrollTrigger() {
      for (let i = 1; i <= 6; i++) {
        const el = document.getElementById('home-card-other-features-' + i);
        this.addObserver(el);
      }
    },
    addObserver(el) {
      // We are creating a new IntersectionObserver instance
      const observer = new IntersectionObserver(
        entries => {
          // This takes a callback function that receives two arguments: the elements list and the observer instance.
          entries.forEach(entry => {
            // `entry.isIntersecting` will be true if the element is visible
            if (entry.isIntersecting) {
              const number = entry.target.id.split('-')[4];
              document
                .getElementById('home-card-other-features-circle-' + number)
                .classList.remove('fal');
              document
                .getElementById('home-card-other-features-circle-' + number)
                .classList.add('fas');
            } else {
              const number = entry.target.id.split('-')[4];
              document
                .getElementById('home-card-other-features-circle-' + number)
                .classList.remove('fas');
              document
                .getElementById('home-card-other-features-circle-' + number)
                .classList.add('fal');
            }
          });
        },
        { threshold: 0.6 }
      );
      // Adding the observer to the element
      observer.observe(el);
    }
  }
});
