

















































import Vue, { PropType } from 'vue';
import { AlertType } from '@/store/ui-store';

export default Vue.extend({
  props: {
    variant: {
      type: String as PropType<AlertType>,
      default: AlertType.DANGER
    },
    message: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: false,
      default: null
    },
    // Fonctionne uniquement si l'alert est créée par le store (il y a un id)
    dismissible: {
      type: Boolean,
      required: false,
      default: false
    },
    // En secondes
    countdown: {
      type: Number,
      required: false,
      default: 10
    }
  },
  data() {
    return {
      AlertType: AlertType
    };
  },
  created() {
    if (this.countdown) {
      setTimeout(() => {
        this.dismiss();
      }, this.countdown * 1000);
    }
  },
  methods: {
    dismiss() {
      this.$emit('dismissed');
    }
  }
});
