








































import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'home'>,
      default: 'md'
    },
    textColor: {
      type: String,
      default: 'white'
    },
    color: {
      type: String,
      default: 'primary-500'
    },
    borderColor: {
      type: String,
      default: 'transparent'
    },
    hoverColor: {
      type: String,
      default: 'primary-600'
    },
    ringColor: {
      type: String,
      default: 'primary-800'
    },
    icon: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: 'fas'
    },
    right: {
      type: Boolean,
      default: false
    },
    buttonLink: {
      type: Boolean,
      default: false
    },
    baseClasses: {
      type: String,
      default:
        'tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2'
    }
  }
});
