import { Role } from '@/model/role';
const MyAccount = () => import('@/components/my-account/MyAccount');
const MyAccountEdit = () => import('@/components/my-account/MyAccountEdit');
const MyAccountEmployeeIntegrations = () =>
  import('@/components/my-account/MyAccountEmployeeIntegrations');
const MyAccountEmployeeEdit = () =>
  import('@/components/my-account/MyAccountEmployeeEdit');
const MyAccountEmployeeSettings = () =>
  import('@/components/my-account/MyAccountEmployeeSettings');

const myAccountRoutes = [
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      role: Role.CUSTOMER,
      public: true
    }
  },
  {
    path: '/my-account/edit',
    name: 'MyAccountEdit',
    component: MyAccountEdit,
    meta: {
      role: Role.CUSTOMER,
      public: true
    }
  },
  {
    path: '/my-account-employee',
    name: 'MyAccountEmployee',
    component: MyAccount,
    meta: {
      role: Role.STAFF
    },
    children: [
      {
        path: 'edit',
        name: 'MyAccountEmployeeEdit',
        component: MyAccountEmployeeEdit,
        meta: {
          role: Role.STAFF
        }
      },
      {
        path: 'settings',
        name: 'MyAccountEmployeeSettings',
        component: MyAccountEmployeeSettings,
        meta: {
          role: Role.STAFF
        }
      },
      {
        path: 'integrations',
        name: 'MyAccountEmployeeIntegrations',
        component: MyAccountEmployeeIntegrations,
        meta: {
          role: Role.STAFF
        }
      }
    ]
  }
];

export default myAccountRoutes;
