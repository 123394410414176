import { Collection } from '@/store/common/collections';
import entitiesService from '@/services/entities.service';
import * as Sentry from '@sentry/vue';
import { Entity } from '@/model/entity';
import i18n from '@/config/i18n';
import {
  EntitiesState,
  EntityConfigurationErrors
} from '@/store/common/entities-state';
import { AlertType, useUiStore } from '@/store/ui-store';
import orderBy from 'lodash/orderBy';
import Vue from 'vue';
import { useEmployeesStore } from '@/store/employees-store';
import { useServicesStore } from '@/store/services-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useLocationsStore } from '@/store/locations-store';
import { useRegionsStore } from '@/store/regions-store';
import { useUsersStore } from '@/store/users-store';
import { useMachineModelsStore } from '@/store/machine-models-store';

export interface EntitiesActions<T> {
  findAll(vendorId: number | null, force?: boolean, loader?: boolean): void;
  findOne(id: number): Promise<T | null>;
  findAllPaginated(payload?: {
    limit?: number;
    page?: number;
    force?: boolean;
    extraQueryParams?: Record<string, string | number>;
  }): void;
  create(
    entity: Partial<T>,
    collectionsToUnsetCurrentEntities?: Collection[]
  ): Promise<T | null>;
  edit(
    entity: Partial<T>,
    collectionsToUnsetCurrentEntities?: Collection[]
  ): Promise<T | null>;
  delete(entity: T): Promise<boolean>;
  setConfigurationErrors(errors: EntityConfigurationErrors[]): void;
  verifyConfiguration(): void;
  setCurrentEntity(entity: T): void;
  unsetCurrentEntity(): void;
  unsetValidationErrors(): void;
  resetAssociatedCollections(associatedCollectionsToReset: Collection[]): void;
}

export function createEntityActions<T>(
  collectionName: Collection,
  state: EntitiesState<T>
): EntitiesActions<T> {
  return {
    async findAll(
      vendorId: number | null = null,
      force: boolean = false,
      loader: boolean = true
    ) {
      const uiStore = useUiStore();
      try {
        if (state.entities.length === 0 || force) {
          if (loader) {
            state.fetching = true;
          }
          if (collectionName === Collection.CATEGORIES) {
            vendorId = null; // Ne doit pas être passé
          }
          state.entities = await entitiesService.findAll(
            collectionName,
            vendorId
          );
          state.fetching = false;
        }
      } catch (exception) {
        console.error(exception);
        state.failure = true;
        state.fetching = false;
        uiStore.alert(exception.message);
        Sentry.captureException(exception);
      }
    },
    async findOne(id: number): Promise<T> {
      const uiStore = useUiStore();
      try {
        state.failure = false;
        state.fetching = true;
        const response = await entitiesService.findOne(collectionName, id);
        const index = state.entities.findIndex(
          entity => ((entity as any) as Entity).id === id
        );
        if (index < 0) {
          state.entities.push(response);
        } else {
          state.entities[index] = response;
        }
        state.fetching = false;
        return response;
      } catch (exception) {
        console.error(exception);
        state.failure = true;
        state.fetching = false;
        uiStore.alert(exception.message);
        Sentry.captureException(exception);
        return null;
      }
    },
    async findAllPaginated(payload?: {
      limit?: number;
      page?: number;
      force?: boolean;
      extraQueryParams?: Record<string, string>;
    }) {
      const uiStore = useUiStore();
      try {
        state.failure = false;
        if (state.entities.length === 0 || payload?.force) {
          state.fetching = true;
          const response = await entitiesService.findAllPaginated(
            collectionName,
            {
              limit: payload?.limit as number,
              page: payload?.page as number,
              ...payload?.extraQueryParams
            }
          );
          state.entities = response.data;
          state.pagination = response.pagination;
          state.fetching = false;
        }
      } catch (exception) {
        console.error(exception);
        state.failure = true;
        state.fetching = false;
        uiStore.alert(exception.message);
        Sentry.captureException(exception);
      }
    },
    async create(entity: T, associatedCollectionsToReset: Collection[] = []) {
      const uiStore = useUiStore();
      state.saving = true;
      state.validationErrors = {};
      try {
        const entityId = (await entitiesService.add(collectionName, entity)).id;
        const entityAdded = await entitiesService.findOne(
          collectionName,
          entityId
        );
        /**
         * Pour les horaires l'ajout peut être en réalité une édition, dans ce cas l'élément ne veut pas ajouter l'élément à double (on le modifie)
         */
        const entityAlreadyExistsIndex: number = state.entities.findIndex(
          // @ts-ignore
          (existingEntity: T) => existingEntity.id === entityId
        );
        let entities: T[] = state.entities;
        if (entityAlreadyExistsIndex >= 0) {
          entities = entities.map(existingEntity => {
            // @ts-ignore
            if (existingEntity.id === entityId) {
              return entityAdded;
            } else {
              return existingEntity;
            }
          });
        } else {
          entities = entities.concat(entityAdded);
        }
        if (state.sortFunction) {
          entities = entities.sort(state.sortFunction);
        }
        Vue.set(state, 'entities', entities);
        if (collectionName === Collection.SERVICES) {
          state.entities = orderBy(state.entities, ['no']);
        } else if (collectionName === Collection.EMPLOYEES) {
          entityAdded.name = `${entityAdded.firstname} ${entityAdded.lastname}`;
        }
        await this.resetAssociatedCollections(associatedCollectionsToReset);
        state.saving = false;
        this.verifyConfiguration();
        if (entityAdded.name) {
          uiStore.alertSuccess(i18n.t('resource.added', [entityAdded.name]));
        } else {
          uiStore.alertSuccess(i18n.t('toast.changesSaved'));
        }

        return entityAdded;
      } catch (exception) {
        console.error(exception);
        state.saving = false;
        if (exception.errors) {
          state.validationErrors = exception.errors;
        }
        uiStore.alert(exception.message);
        Sentry.captureException(exception);
        return null;
      }
    },
    async edit(entity: T, associatedCollectionsToReset: Collection[] = []) {
      const uiStore = useUiStore();
      state.saving = true;
      state.validationErrors = {};
      try {
        await entitiesService.edit(collectionName, entity);
        const entityEdited = await entitiesService.findOne(
          collectionName,
          ((entity as unknown) as Entity).id
        );
        const index = state.entities.findIndex(
          loopEntity =>
            ((loopEntity as unknown) as Entity).id ===
            ((entity as unknown) as Entity).id
        );
        if (index >= 0) {
          Vue.set(state.entities, index, entityEdited);
        }
        if (
          ((state.currentEntity as unknown) as Entity).id ===
          ((entity as unknown) as Entity).id
        ) {
          state.currentEntity = entityEdited;
        }
        await this.resetAssociatedCollections(associatedCollectionsToReset);
        state.saving = false;
        this.verifyConfiguration();
        uiStore.alertSuccess(i18n.t('resource.edited', [entityEdited.name]));

        return entityEdited;
      } catch (exception) {
        console.error(exception);
        state.saving = false;
        if (exception.errors) {
          state.validationErrors = exception.errors;
        }
        uiStore.alert(
          exception.message,
          AlertType.DANGER,
          10,
          exception.errors
        );
        Sentry.captureException(exception);

        return null;
      }
    },
    async resetAssociatedCollections(
      associatedCollectionsToReset: Collection[]
    ) {
      for (const collection of associatedCollectionsToReset) {
        const userStore = useUsersStore();
        let store;
        switch (collection) {
          case Collection.EMPLOYEES:
            store = useEmployeesStore();
            await store.findAll(null, true);
            break;
          case Collection.SERVICES:
            store = useServicesStore();
            await store.findAll(userStore.loggedInVendorId, true);
            break;
          case Collection.WORKPLACES:
            store = useWorkplacesStore();
            await store.findAll(userStore.loggedInVendorId, true);
            break;
          case Collection.LOCATIONS:
            store = useLocationsStore();
            await store.findAll(userStore.loggedInVendorId, true);
            break;
          case Collection.REGIONS:
            store = useRegionsStore();
            await store.findAll(userStore.loggedInVendorId, true);
            break;
          case Collection.MACHINE_MODELS:
            store = useMachineModelsStore();
            await store.findAll(userStore.loggedInVendorId, true);
            break;
        }
        store.unsetCurrentEntity();
        store.verifyConfiguration();
      }
    },
    async delete(entity: T) {
      const uiStore = useUiStore();
      try {
        await entitiesService.delete(collectionName, entity);
        state.entities = state.entities.filter(existingEntity => {
          return (
            ((existingEntity as unknown) as Entity).id !==
            ((entity as unknown) as Entity).id
          );
        });
        this.verifyConfiguration();
        return true;
      } catch (exception) {
        console.error(exception);
        uiStore.alert(exception.message);
        Sentry.captureException(exception);

        return false;
      }
    },
    setConfigurationErrors(errors: EntityConfigurationErrors[]) {
      state.configurationErrors = errors;
    },
    // Implémenté dans chaque state
    verifyConfiguration() {},
    setCurrentEntity(entity: T): void {
      state.currentEntity = entity;
    },
    unsetCurrentEntity(): void {
      state.currentEntity = ({} as unknown) as T;
    },
    unsetValidationErrors(): void {
      state.validationErrors = {};
    }
  };
}
