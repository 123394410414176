









































import { getAvailableLanguages } from '@/config/i18n';
import { mapStores } from 'pinia';
import Vue from 'vue';
import { useUsersStore } from '@/store/users-store';

export default Vue.extend({
  props: {
    direction: {
      default: 'up',
      type: String
    }
  },
  data() {
    return {
      availableLanguages: getAvailableLanguages(),
      visible: false
    };
  },
  computed: {
    ...mapStores(useUsersStore),
    isLoggedIn(): boolean {
      return this.usersStore.isLoggedIn;
    },
    loggedInUserName(): string | null {
      return this.usersStore.user.entity.firstname;
    },
    loggedInEmployeeId(): number | null {
      return this.usersStore.loggedInEmployeeId;
    },
    loggedInUserId(): number | null {
      return this.usersStore.loggedInUserId;
    }
  },
  methods: {
    logoutClicked() {
      this.usersStore.logout();
    },
    setLanguage(language: string) {
      this.usersStore.setLanguage(language);
    }
  }
});
