/** @deprecated **/
export const ROLE = Object.freeze({
  GUEST: -1,
  NEW: 0,
  CUSTOMER: 1,
  STAFF: 2,
  ADMIN: 3
});

export enum Role {
  GUEST = -1,
  NEW = 0,
  CUSTOMER = 1,
  STAFF = 2,
  ADMIN = 3
}
