import Vue from 'vue';
import VueRouter from 'vue-router';
import customersRoutes from './customers.routes';
import employeesRoutes from './employees.routes';
import bookingsRoutes from './bookings.routes';
import locationsRoutes from '@/routes/locations.routes';
import vendorsRoutes from '@/routes/vendors.routes';
import { Role } from '@/model/role';
import myAccountRoutes from '@/routes/my-account.routes';
import calendarRoutes from '@/routes/calendar.routes';
import { SiteSection, useUiStore } from '@/store/ui-store';
import { useUsersStore } from '@/store/users-store';
import { createPinia, PiniaVuePlugin } from 'pinia/dist/pinia';
import { CalendarResourceType } from '@/store/calendar-store';
import publicRoutes from '@/routes/public.routes';

Vue.use(PiniaVuePlugin);
export const pinia = createPinia();
Vue.use(VueRouter);

// const HomePage = () => import('@/components/pages/HomePage');
const VendorSignUpPage = () => import('@/components/pages/VendorSignUpPage');
const Login = () => import('@/components/auth/Login');
const ForgotPassword = () => import('@/components/auth/ForgotPassword');
const ResetPassword = () => import('@/components/auth/ResetPassword');
const ForgotPasswordConfirmation = () =>
  import('@/components/auth/ForgotPasswordConfirmation');
const EmailValidation = () => import('@/components/auth/EmailValidation');
const SignUp = () => import('@/components/auth/SignUp');
const SignUpConfirmation = () => import('@/components/auth/SignUpConfirmation');
const GeneralTerms = () => import('@/components/GeneralTerms');
const Vouchers = () => import('@/components/vouchers/Vouchers');
const Services = () => import('@/components/services/Services');
const Workplaces = () => import('@/components/workplaces/Workplaces');
const Machines = () => import('@/components/machines/Machines');
const Timetables = () => import('@/components/timetables/Timetables');
const CashClosing = () => import('@/components/cash-closing/CashClosing.vue');
const Regions = () => import('@/components/regions/Regions.vue');
const ClosedDates = () => import('@/components/closed-dates/ClosedDates.vue');

const routes = [
  /* {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      public: true
    }
  }, */
  {
    path: '/vendor-sign-up',
    name: 'VendorSignUp',
    component: VendorSignUpPage,
    meta: {
      public: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      role: Role.GUEST,
      public: true
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
    meta: {
      role: Role.GUEST
    }
  },
  {
    path: '/sign-up-confirmation',
    name: 'SignUpConfirmation',
    component: SignUpConfirmation,
    meta: {
      role: Role.GUEST
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      role: Role.GUEST,
      public: true
    }
  },
  {
    path: '/forgot-password-confirmation',
    name: 'ForgotPasswordConfirmation',
    component: ForgotPasswordConfirmation,
    meta: {
      role: Role.GUEST
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      role: Role.GUEST
    }
  },
  {
    path: '/email-validation',
    name: 'EmailValidation',
    component: EmailValidation,
    meta: {
      role: Role.GUEST
    }
  },
  {
    path: '/general-terms',
    name: 'GeneralTerms',
    component: GeneralTerms,
    meta: {
      role: Role.CUSTOMER
    }
  },
  {
    path: '/vouchers',
    name: 'Vouchers',
    component: Vouchers,
    meta: {
      role: Role.STAFF
    }
  },
  {
    path: '/reports/cash-closing',
    name: 'CashClosing',
    component: CashClosing,
    meta: {
      role: Role.ADMIN
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta: {
      role: Role.ADMIN
    }
  },
  {
    path: '/workplaces',
    name: 'Workplaces',
    component: Workplaces,
    meta: {
      role: Role.ADMIN
    }
  },
  {
    path: '/machines',
    name: 'Machines',
    component: Machines,
    meta: {
      role: Role.ADMIN
    }
  },
  {
    path: '/timetables',
    name: 'Timetables',
    component: Timetables,
    meta: {
      role: Role.STAFF
    }
  },
  {
    path: '/regions',
    name: 'Regions',
    component: Regions,
    meta: {
      role: Role.ADMIN
    }
  },
  {
    path: '/closed-dates',
    name: 'ClosedDates',
    component: ClosedDates,
    meta: {
      role: Role.ADMIN
    }
  }
].concat(
  customersRoutes,
  employeesRoutes,
  bookingsRoutes,
  locationsRoutes,
  myAccountRoutes,
  calendarRoutes,
  vendorsRoutes,
  publicRoutes
);

const router = new VueRouter({
  routes,
  mode: 'history',
  base: '/',
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const uiStore = useUiStore(pinia);
  const usersStore = useUsersStore(pinia);
  uiStore.setSiteSection(
    !to.meta.public ? SiteSection.ADMIN : SiteSection.PUBLIC
  );

  const loggedIn = usersStore.isLoggedIn;
  const userRole = usersStore.loggedInUserRole;
  const requiredRole = to.meta.role;
  if (to.name === 'Calendar' && !to.query.viewType) {
    to.query.viewType = CalendarResourceType.EMPLOYEE;
  }
  if (requiredRole === Role.GUEST && loggedIn) {
    if (to.path.includes('/pages/')) {
      next();
    } else {
      usersStore.logout();
      next();
    }
  } else if (requiredRole > Role.GUEST) {
    if (loggedIn) {
      if (requiredRole > userRole) {
        uiStore.alert(router.app.$t('guard.insufficientPermissions'));
        if (userRole >= Role.STAFF) {
          next({
            name: 'Calendar',
            query: { viewType: CalendarResourceType.EMPLOYEE }
          });
        }
        next(false);
      } else {
        next();
      }
    } else {
      uiStore.alert(router.app.$t('guard.notLoggedIn'));
      next({ name: 'Login', query: { redirect: to.name } });
    }
  } else {
    next();
  }
});

export default router;
