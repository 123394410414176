var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'tw-overflow-x-hidden': _vm.siteSection === _vm.SITE_SECTION.PUBLIC },attrs:{"id":"app"}},[_c('div',{class:{
      'tw-fixed tw-h-full tw-w-full tw-bg-white tw-opacity-90 tw-z-10': _vm.backdrop
    },attrs:{"id":"backdrop"}}),_c('div',{staticClass:"tw-fixed tw-top-0 tw-z-100 tw-w-full tw-bg-white shadow",class:{
      'tw-hidden': _vm.mobileLandscape && _vm.calendarPage,
      'xl:tw-hidden': _vm.siteSection === _vm.SITE_SECTION.ADMIN
    }},[_c('Navbar')],1),_c('div',{staticClass:"tw-fixed tw-z-10000 tw-w-full lg:tw-w-1/2 lg:tw-left-1/4 tw-px-4 lg:tw-px-0"},_vm._l((_vm.alerts),function(alert){return _c('div',{key:alert.key,staticClass:"tw-mt-3"},[_c('alert',{attrs:{"id":alert.key,"message":alert.message,"variant":alert.type,"duration":alert.countdown,"dismissible":true},on:{"dismissed":function($event){return _vm.clearAlert(alert.key)}}})],1)}),0),_c('section',{staticClass:"tw-min-h-screen-navbar",class:{
      'tw-h-screen-navbar xl:tw-mt-0': _vm.siteSection === _vm.SITE_SECTION.ADMIN,
      'tw-mt-16': !(_vm.mobileLandscape && _vm.calendarPage)
    }},[_c('div',{staticClass:"tw-relative tw-flex"},[_c('div',{staticClass:"tw-absolute tw-justify-center tw-items-center round-position tw-w-7 tw-h-7 tw-rounded-full tw-ring-1 tw-ring-inset tw-ring-light tw-bg-white tw-text-primary-500 tw-shadow-lg tw-cursor-pointer tw-transition-all tw-duration-1000",class:[
          _vm.reducedSidebar ? 'round-position-reduced' : 'round-position',
          _vm.siteSection === _vm.SITE_SECTION.ADMIN
            ? 'tw-hidden xl:tw-inline-flex'
            : 'tw-hidden'
        ],on:{"click":function($event){return _vm.reduceSidebar()}}},[_c('i',{staticClass:"fas fa-chevron-right tw-transition-all tw-duration-1000 tw-transform",class:[
            _vm.reducedSidebar ? 'tw-rotate-0' : 'tw-rotate-180 tw-mr-0.5'
          ]})]),(_vm.isStaffOrMore && _vm.siteSection === _vm.SITE_SECTION.ADMIN)?_c('div',{staticClass:"tw-hidden tw-z-0 tw-flex-none tw-h-screen xl:tw-flex xl:tw-sticky xl:tw-top-0 tw-overflow-hidden tw-bg-white scrollbar-thin tw-transition-all tw-duration-1000",class:[_vm.reducedSidebar ? 'tw-w-20' : 'tw-w-80']},[_c('sidebar',{attrs:{"reduced":_vm.reducedSidebar}})],1):_vm._e(),_c('section',{staticClass:"tw-flex-grow"},[_c('router-view')],1)])]),_c('div',[(_vm.siteSection === _vm.SITE_SECTION.PUBLIC)?_c('app-footer'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }