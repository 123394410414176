import { defineStore } from 'pinia';
import moment from 'moment';
import { Schedule } from '@/model/schedule';
import { Event } from '@/model/event';
import { MomentHelper } from '@/helpers/moment.helper';
import employeeService from '@/services/employee.service';
import eventsService from '@/services/events.service';
import * as Sentry from '@sentry/vue';
import { Location } from '@/model/location';
import Vue from 'vue';
import { API_DATE_FORMAT } from '@/constants';
import sortBy from 'lodash/sortBy';
import { CalendarEvent } from '@/components/calendar/models/calendar-event';
import { Category } from '@/model/category';
import cloneDeep from 'lodash/cloneDeep';

const storeId = 'calendar';

/**
 * Create schedules for workplaces and machines from 00:00:00 until 23:59:59
 */
function getResourceSchedules(dateFrom: string, dateTo: string, id: number) {
  const schedules: (Partial<Schedule> & { entityId: number })[] = [];
  const currentDate = moment(dateFrom, API_DATE_FORMAT);
  const endDate = moment(dateTo, API_DATE_FORMAT);
  do {
    schedules.push({
      available: true,
      date: currentDate.format(API_DATE_FORMAT),
      entityId: id,
      isException: false,
      notAvailableSummary: null,
      timeRanges: [
        {
          start: currentDate
            .clone()
            .hours(0)
            .minutes(0)
            .seconds(0)
            .format(),
          end: currentDate
            .clone()
            .hours(23)
            .minutes(59)
            .seconds(59)
            .format(),
          location: {} as Location,
          own_category: null,
          possible_categories: []
        }
      ]
    });
    currentDate.add(1, 'day');
  } while (currentDate.isSameOrBefore(endDate));
  return schedules;
}

export enum CalendarResourceType {
  EMPLOYEE = 'EMPLOYEE',
  MACHINE = 'MACHINE',
  WORKPLACE = 'WORKPLACE'
}

export enum CalendarViewMode {
  PERSONAL = 'PERSONAL',
  TEAM = 'TEAM'
}

export enum CalendarDisplayType {
  PLANNING = 'PLANNING',
  DAY_GRID = 'DAY_GRID',
  WEEK_GRID = 'WEEK_GRID'
}

export interface CalendarSchedulesMap {
  [id: number]: {
    [date: string]: Schedule;
  };
}

export interface CalendarEventsMap {
  [id: number]: {
    [date: string]: Event[];
  };
}

export interface CalendarSelectedMonth {
  monthIndex: number;
  year: number;
}

let initialState = {
  viewType: CalendarResourceType.EMPLOYEE,
  viewMode: CalendarViewMode.PERSONAL,
  displayType: CalendarDisplayType.DAY_GRID,
  previousDisplayType: null as CalendarDisplayType | null,
  forceShowSchedule: false as boolean,
  reduced: false as boolean,
  selectedMonth: {
    monthIndex: moment().month() as number,
    year: moment().year() as number
  } as CalendarSelectedMonth,
  selectedDate: moment().format(API_DATE_FORMAT) as string,
  startOfDay: 8,
  zoom: 0 as number,
  schedules: {
    fetching: false as boolean,
    failure: false as boolean,
    schedules: {
      [CalendarResourceType.EMPLOYEE]: {} as CalendarSchedulesMap,
      [CalendarResourceType.MACHINE]: {} as CalendarSchedulesMap,
      [CalendarResourceType.WORKPLACE]: {} as CalendarSchedulesMap
    }
  },
  events: {
    fetching: false as boolean,
    failure: false as boolean,
    events: {
      [CalendarResourceType.EMPLOYEE]: {} as CalendarEventsMap,
      [CalendarResourceType.MACHINE]: {} as CalendarEventsMap,
      [CalendarResourceType.WORKPLACE]: {} as CalendarEventsMap
    }
  }
};
const initialStateClone = cloneDeep(initialState);
if (localStorage.getItem(storeId)) {
  initialState = {
    ...initialState,
    ...JSON.parse(localStorage.getItem(storeId))
  };
}

export const useCalendarStore = defineStore(storeId, {
  state: () => {
    return {
      ...initialState
    };
  },
  getters: {
    getSchedule: state => (
      entityId: number,
      dateString: string
    ): Schedule | null => {
      try {
        return (
          state.schedules.schedules[state.viewType]?.[entityId]?.[dateString] ??
          null
        );
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    getEvents: state => (
      entityId: number,
      dateString: string
    ): Event[] | [] => {
      try {
        return (
          state.events.events[state.viewType]?.[entityId]?.[dateString] ?? []
        );
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  },
  actions: {
    getCalendarEvents(entityId: number, date: string): CalendarEvent[] {
      const events: Event[] =
        this.events.events[this.viewType]?.[entityId]?.[date] ?? [];
      let calendarEvents: CalendarEvent[] = [];
      const eventsStartsToSkip: string[] = [];

      /**
       * On ajoute tous les CalendarEvent en regroupant les Booking
       */
      events.forEach((event: Event) => {
        const calEvent: CalendarEvent = {
          start: event.start,
          end: event.end,
          entity_id: entityId,
          event_id: event.id,
          summary: event.summary,
          customer: event.booking?.customer ?? null,
          workplace_id: event.workplace_id,
          location_id: event.location_id,
          comment: event.booking?.comment ?? null,
          services_ids: event.booking ? [event.service_id] : null,
          services_names: event.booking ? [event.service.name] : null,
          booking_id: event.booking ? event.booking_id : null
        };
        if (eventsStartsToSkip.includes(calEvent.start)) {
          return;
        }
        if (calEvent.booking_id) {
          const eventsOfBookingWithSameWorkplace: Event[] = events.filter(
            loopEvent => {
              return (
                loopEvent.booking_id === calEvent.booking_id &&
                loopEvent.workplace_id === calEvent.workplace_id &&
                loopEvent.service_part_id === null
              );
            }
          );
          let ownCategory: Category = null;
          if (calEvent.own_category) {
            const schedule: Partial<Schedule> = this.schedules.schedules[
              this.viewType
            ][entityId]
              ? this.schedules.schedules[this.viewType][entityId][date]
              : { timeRanges: [] };
            if (schedule?.timeRanges) {
              const timeRange = schedule.timeRanges.find(timeRange => {
                return MomentHelper.checkIfTimeRangesOverlap(
                  moment(calEvent.start),
                  moment(calEvent.end),
                  moment(timeRange.start),
                  moment(timeRange.end),
                  true
                );
              });
              ownCategory = timeRange?.own_category ?? null;
            }
          }
          if (eventsOfBookingWithSameWorkplace.length > 1) {
            const servicesIds: number[] = [];
            const servicesNames: string[] = [];
            eventsOfBookingWithSameWorkplace.forEach(e => {
              servicesIds.push(e.service_id);
              servicesNames.push(e.service.name);
              eventsStartsToSkip.push(e.start); // Sinon on va ajouter à double les autres événements du booking
            });
            calendarEvents.push({
              start: eventsOfBookingWithSameWorkplace[0].start,
              end:
                eventsOfBookingWithSameWorkplace[
                  eventsOfBookingWithSameWorkplace.length - 1
                ].end,
              entity_id: entityId,
              workplace_id: eventsOfBookingWithSameWorkplace[0].workplace_id,
              location_id: eventsOfBookingWithSameWorkplace[0].location_id,
              own_category: ownCategory,
              booking_id: eventsOfBookingWithSameWorkplace[0].booking_id,
              customer: eventsOfBookingWithSameWorkplace[0].booking.customer,
              comment: eventsOfBookingWithSameWorkplace[0].booking.comment,
              services_ids: servicesIds,
              services_names: servicesNames
            });
          } else {
            // 1 seul événement dans le Booking
            calendarEvents.push(calEvent);
          }
        } else {
          // Event (pas un Booking)
          calendarEvents.push(calEvent);
        }
      });

      calendarEvents = calendarEvents.filter(item => {
        return !moment(item.start).isSame(moment(item.end), 'minute');
      });
      calendarEvents = calendarEvents.sort(function compare(itemA, itemB) {
        return moment(itemA.start).isAfter(moment(itemB.start)) ? 1 : -1;
      });

      return calendarEvents;
    },
    setViewMode(viewMode: CalendarViewMode) {
      this.viewMode = viewMode;
    },
    setViewType(viewType: CalendarResourceType) {
      this.viewType = viewType;
    },
    setDisplayType(displayType: CalendarDisplayType) {
      this.previousDisplayType =
        this.displayType !== CalendarDisplayType.WEEK_GRID
          ? this.displayType
          : null;
      this.displayType = displayType;
    },
    changeDayOrWeek(increment: number, unit: 'day' | 'week') {
      if (
        moment(this.selectedDate).month() !==
        moment(this.selectedDate)
          .add(increment, unit)
          .month()
      ) {
        this.changeMonth(increment);
      }
      this.selectDate(
        moment(this.selectedDate)
          .add(increment, unit)
          .format(API_DATE_FORMAT)
      );
    },
    changeMonth(increment: number) {
      const nextMonth = moment()
        .month(this.selectedMonth.monthIndex)
        .year(this.selectedMonth.year)
        .add(increment, 'months');
      this.setMonthYear({
        monthIndex: nextMonth.month(),
        year: nextMonth.year()
      });
    },
    setStartOfDay(startOfDay: number) {
      this.startOfDay = startOfDay;
    },
    setZoom(zoomLevel: number) {
      this.zoom = zoomLevel;
    },
    selectDate(date: string) {
      this.$patch({
        selectedDate: date
      });
    },
    setMonthYear(month: CalendarSelectedMonth) {
      this.selectedMonth = month;
    },
    addEvent(event: Event, date: string, entityId: string | number) {
      if (!this.events.events[this.viewType][entityId]) {
        Vue.set(this.events.events[this.viewType], entityId, {});
      }
      if (Array.isArray(this.events.events[this.viewType][entityId][date])) {
        Vue.set(this.events.events[this.viewType][entityId], date, [
          ...this.events.events[this.viewType][entityId][date],
          ...[event]
        ]);
        this.events.events[this.viewType][entityId][date] = sortBy(
          this.events.events[this.viewType][entityId][date],
          ['start']
        );
      } else {
        Vue.set(this.events.events[this.viewType][entityId], date, [event]);
      }
    },
    deleteEvent(eventOrEventId: Event | number, entityId: number) {
      const eventId: number =
        typeof eventOrEventId === 'number' ? eventOrEventId : eventOrEventId.id;
      if (this.events.events[this.viewType][entityId][this.selectedDate]) {
        Vue.set(
          this.events.events[this.viewType][entityId],
          this.selectedDate,
          this.events.events[this.viewType][entityId][this.selectedDate].filter(
            loopEvent => loopEvent.id !== eventId
          )
        );
      }
    },
    deleteEventByBookingId(bookingId: number, entityId: number) {
      if (this.events.events[this.viewType][entityId][this.selectedDate]) {
        Vue.set(
          this.events.events[this.viewType][entityId],
          this.selectedDate,
          this.events.events[this.viewType][entityId][this.selectedDate].filter(
            loopEvent => loopEvent.booking_id !== bookingId
          )
        );
      }
    },
    async findSchedulesBetweenDates(payload: {
      employeeId?: number;
      workplaceId?: number;
      machineId?: number;
      dateFrom: string;
      dateTo: string;
      resourcesIds?: number[];
    }) {
      if (this.viewType !== CalendarResourceType.EMPLOYEE) {
        let schedules: any[] = [];
        if (payload.resourcesIds) {
          for (const resourceId of payload.resourcesIds) {
            schedules = [
              ...schedules,
              ...getResourceSchedules(
                payload.dateFrom,
                payload.dateTo,
                resourceId
              )
            ];
          }
        } else {
          schedules = getResourceSchedules(
            payload.dateFrom,
            payload.dateTo,
            (payload.workplaceId as number) ?? (payload.machineId as number)
          );
        }
        this._setSchedules(schedules);
        return;
      }
      try {
        this.schedules.fetching = true;
        this.schedules.failure = false;
        const schedules = (
          await employeeService.getSchedulesBetweenDates(
            payload.dateFrom,
            payload.dateTo,
            payload.employeeId
          )
        ).map(schedule => ({ ...schedule, entityId: schedule.employeeId }));
        this._setSchedules(schedules);
      } catch (exception) {
        this.schedules.fetching = false;
        this.schedules.failure = true;
      }
    },
    _setSchedules(schedules: Schedule[]) {
      schedules.forEach(schedule => {
        if (
          typeof this.schedules.schedules[this.viewType][
            schedule.entityId as number
          ] === 'undefined'
        ) {
          Vue.set(
            this.schedules.schedules[this.viewType],
            schedule.entityId as number,
            {}
          );
        }
        Vue.set(
          this.schedules.schedules[this.viewType][schedule.entityId as number],
          schedule.date,
          schedule
        );
      });
      this.$patch({
        schedules: {
          ...this.schedules,
          fetching: false
        }
      });
    },
    async findEventsBetweenDates(payload: {
      employeeId?: number;
      workplaceId?: number;
      machineId?: number;
      dateFrom: string;
      dateTo: string;
    }) {
      try {
        this.events.fetching = true;
        this.events.failure = false;
        const events = await eventsService.findBetweenDates(
          payload.dateFrom,
          payload.dateTo,
          payload.employeeId,
          payload.machineId,
          payload.workplaceId
        );
        this._setEvents(events, payload.dateFrom, payload.dateTo);
      } catch (exception) {
        console.error(exception);
        Sentry.captureException(exception);
        this.events.failure = true;
        this.events.fetching = false;
      }
    },
    _setEvents(events: Event[], dateFrom: string, dateTo: string) {
      const viewType: CalendarResourceType = this.viewType;
      events.forEach(event => {
        const entityId: number =
          viewType === CalendarResourceType.WORKPLACE
            ? (event.workplace_id as number)
            : viewType === CalendarResourceType.MACHINE
            ? (event.machine_id as number)
            : (event.employee_id as number);
        const dateKey = moment(event.start).format(API_DATE_FORMAT);
        if (typeof this.events.events[viewType][entityId] === 'undefined') {
          return;
        }
        if (
          this.events.events[viewType][entityId][dateKey] &&
          this.events.events[viewType][entityId][dateKey].length > 0
        ) {
          Vue.set(this.events.events[viewType], entityId, {
            ...this.events.events[viewType][entityId],
            [dateKey]: []
          });
        }
      });
      // Reset nécessaire à cause des suppression des modifications / annulations déjà présentes dans le state
      Object.keys(this.events.events[viewType]).forEach(entityId => {
        const date = moment(dateFrom);
        while (date.isSameOrBefore(moment(dateTo))) {
          Vue.set(this.events.events[viewType], entityId, {});
          date.add(1, 'day');
        }
      });
      // Ajout des événements
      events.forEach(event => {
        const entityId: number =
          viewType === CalendarResourceType.WORKPLACE
            ? (event.workplace_id as number)
            : viewType === CalendarResourceType.MACHINE
            ? (event.machine_id as number)
            : (event.employee_id as number);
        const dateKey = moment(event.start).format(API_DATE_FORMAT);
        if (typeof this.events.events[viewType][entityId] === 'undefined') {
          Vue.set(this.events.events[viewType], entityId, {});
        }
        if (
          typeof this.events.events[viewType][entityId][dateKey] === 'undefined'
        ) {
          Vue.set(this.events.events[viewType], entityId, {
            ...this.events.events[viewType][entityId],
            [dateKey]: []
          });
        }
        const existingEventIndex = this.events.events[viewType][entityId][
          dateKey
        ].findIndex(ev => ev.id === event.id);
        if (existingEventIndex >= 0) {
          this.events.events[viewType][entityId][dateKey][
            existingEventIndex
          ] = event;
        } else {
          this.events.events[viewType][entityId][dateKey].push(event);
        }
      });
      this.$patch({
        events: {
          ...this.events,
          fetching: false
        }
      });
    },
    reset() {
      Vue.set(this, '$state', cloneDeep(initialStateClone));
    }
  }
});
