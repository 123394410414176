export class MathHelper {
  static getMedian(items: number[]): number {
    items.sort((a, b) => a - b);
    const length = items.length;

    if (length % 2 === 0) {
      return (items[length / 2] + items[length / 2 - 1]) / 2;
    } else {
      return items[Math.floor(length / 2)];
    }
  }
}
