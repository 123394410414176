import { defineStore } from 'pinia';
import { Collection } from '@/store/common/collections';
import alertHelper from '@/helpers/alert.helper';
import VueI18n from 'vue-i18n';
import LocaleMessages = VueI18n.LocaleMessages;
import tailwind from '../../tailwind.config';
import { io, Socket } from 'socket.io-client';

/**
 * Employees
 */

export enum EmployeePanel {
  EMPLOYEES_LIST = 'EMPLOYEES_LIST',
  ADD_EMPLOYEE = 'ADD_EMPLOYEE',
  EDIT_EMPLOYEE = 'EDIT_EMPLOYEE',
  ADD_SERVICE = 'ADD_SERVICE',
  ADD_WORKPLACE = 'ADD_WORKPLACE'
}

export const EMPLOYEES_PANELS_LAYOUT: EmployeePanel[][] = [
  [EmployeePanel.EMPLOYEES_LIST],
  [EmployeePanel.ADD_EMPLOYEE, EmployeePanel.EDIT_EMPLOYEE],
  [EmployeePanel.ADD_SERVICE, EmployeePanel.ADD_WORKPLACE]
];

/**
 * Timetables
 */

export enum TimetablePanel {
  TIMETABLES_LIST = 'TIMETABLES_LIST',
  ADD_TIMETABLE = 'ADD_TIMETABLE',
  EDIT_TIMETABLE = 'EDIT_TIMETABLE',
  ADD_TIMETABLE_EXCEPTION = 'ADD_TIMETABLE_EXCEPTION',
  EDIT_TIMETABLE_EXCEPTION = 'EDIT_TIMETABLE_EXCEPTION'
}

export const TIMETABLE_PANELS_LAYOUT: TimetablePanel[][] = [
  [TimetablePanel.TIMETABLES_LIST],
  [
    TimetablePanel.ADD_TIMETABLE,
    TimetablePanel.EDIT_TIMETABLE,
    TimetablePanel.ADD_TIMETABLE_EXCEPTION,
    TimetablePanel.EDIT_TIMETABLE_EXCEPTION
  ]
];

/**
 * Services
 */

export enum ServicePanel {
  SERVICES_LIST = 'SERVICES_LIST',
  ADD_SERVICE = 'ADD_SERVICE',
  EDIT_SERVICE = 'EDIT_SERVICE',
  ADD_EMPLOYEE = 'ADD_EMPLOYEE',
  ADD_WORKPLACE = 'ADD_WORKPLACE',
  ADD_MACHINE = 'ADD_MACHINE'
}

export const SERVICES_PANELS_LAYOUT: ServicePanel[][] = [
  [ServicePanel.SERVICES_LIST],
  [ServicePanel.ADD_SERVICE, ServicePanel.EDIT_SERVICE],
  [
    ServicePanel.ADD_EMPLOYEE,
    ServicePanel.ADD_WORKPLACE,
    ServicePanel.ADD_MACHINE
  ]
];

/**
 * Workplaces
 */

export enum WorkplacePanel {
  WORKPLACES_LIST = 'WORKPLACES_LIST',
  ADD_WORKPLACE = 'ADD_WORKPLACE',
  EDIT_WORKPLACE = 'EDIT_WORKPLACE',
  ADD_MACHINE = 'ADD_MACHINE',
  ADD_SERVICE = 'ADD_SERVICE',
  ADD_EMPLOYEE = 'ADD_EMPLOYEE'
}

export const WORKPLACES_PANELS_LAYOUT: WorkplacePanel[][] = [
  [WorkplacePanel.WORKPLACES_LIST],
  [WorkplacePanel.ADD_WORKPLACE, WorkplacePanel.EDIT_WORKPLACE],
  [
    WorkplacePanel.ADD_MACHINE,
    WorkplacePanel.ADD_SERVICE,
    WorkplacePanel.ADD_EMPLOYEE
  ]
];

/**
 * Regions
 */

export enum RegionPanel {
  REGIONS_LIST = 'REGIONS_LIST',
  ADD_REGION = 'ADD_REGION',
  EDIT_REGION = 'EDIT_REGION',
  ADD_LOCATION = 'ADD_LOCATION'
}

export const REGIONS_PANELS_LAYOUT: RegionPanel[][] = [
  [RegionPanel.REGIONS_LIST],
  [RegionPanel.ADD_REGION, RegionPanel.EDIT_REGION],
  [RegionPanel.ADD_LOCATION]
];

/**
 * Locations
 */

export enum LocationPanel {
  LOCATIONS_LIST = 'LOCATIONS_LIST',
  ADD_LOCATION = 'ADD_LOCATION',
  EDIT_LOCATION = 'EDIT_LOCATION',
  ADD_REGION = 'ADD_REGION'
}

export const LOCATIONS_PANELS_LAYOUT: LocationPanel[][] = [
  [LocationPanel.LOCATIONS_LIST],
  [LocationPanel.ADD_LOCATION, LocationPanel.EDIT_LOCATION],
  [LocationPanel.ADD_REGION]
];

/**
 * Closed Dates
 */

export enum ClosedDatePanel {
  CLOSED_DATE_LIST = 'CLOSED_DATE_LIST',
  ADD_CLOSED_DATE = 'ADD_CLOSED_DATE',
  EDIT_CLOSED_DATE = 'EDIT_CLOSED_DATE'
}

export const CLOSE_DATE_PANELS_LAYOUT = [
  [ClosedDatePanel.CLOSED_DATE_LIST],
  [ClosedDatePanel.ADD_CLOSED_DATE, ClosedDatePanel.EDIT_CLOSED_DATE]
];

/**
 * Machines
 */

export enum MachinePanel {
  MACHINES_LIST = 'MACHINES_LIST',
  ADD_MACHINE = 'ADD_MACHINE',
  EDIT_MACHINE = 'EDIT_MACHINE',
  ADD_WORKPLACE = 'ADD_WORKPLACE',
  ADD_SERVICE = 'ADD_SERVICE'
}

export const MACHINES_PANELS_LAYOUT: MachinePanel[][] = [
  [MachinePanel.MACHINES_LIST],
  [MachinePanel.ADD_MACHINE, MachinePanel.EDIT_MACHINE],
  [MachinePanel.ADD_WORKPLACE, MachinePanel.ADD_SERVICE]
];

export type Panel =
  | ClosedDatePanel
  | EmployeePanel
  | LocationPanel
  | MachinePanel
  | RegionPanel
  | ServicePanel
  | TimetablePanel
  | WorkplacePanel;

export enum SiteSection {
  PUBLIC = 'PUBLIC',
  ADMIN = 'ADMIN'
}

export enum AlertType {
  WARNING = 'warning',
  SUCCESS = 'success',
  DANGER = 'danger',
  INFO = 'info'
}

export interface IAlert {
  type: AlertType;
  message: string;
  countdown: number;
  key: number;
}

export interface ServerToClientEvents {
  events: (message: {
    vendorId: number;
    employeeId: number | null;
    bookingId: number | null;
  }) => void;
}

export interface ClientToServerEvents {}

export const useUiStore = defineStore('ui', {
  state: () => ({
    siteSection: null as SiteSection | null,
    openedPanels: [] as Panel[],
    notificationsCount: {
      [Collection.EMPLOYEES]: 0,
      [Collection.LOCATIONS]: 0,
      [Collection.MACHINES]: 0,
      [Collection.REGIONS]: 0,
      [Collection.SERVICES]: 0,
      [Collection.WORKPLACES]: 0,
      [Collection.CLOSED_DATES]: 0
    } as Record<Collection, number>,
    alerts: [] as IAlert[],
    backdrop: false as boolean,
    reducedSidebar: false as boolean,
    screenOrientation: 'landscape' as 'portrait' | 'landscape',
    screenWidth: null as number,
    socket: io('https://rubenpmo.ovh') as Socket<
      ServerToClientEvents,
      ClientToServerEvents
    >
  }),
  getters: {
    getNotificationsCount: state => (sidebarItem: Collection): number => {
      return state.notificationsCount[sidebarItem];
    },
    currentPanelIndex: state => {
      return state.openedPanels.length - 1;
    },
    currentPanel: state => {
      return state.openedPanels[state.openedPanels.length - 1];
    },
    isPanelOpened: state => (panelName: Panel) => {
      return state.openedPanels.indexOf(panelName) >= 0;
    },
    mobileLandscape: state => {
      return (
        state.screenOrientation === 'landscape' &&
        state.screenWidth < parseInt(tailwind.theme.screens.lg, 10)
      );
    }
  },
  actions: {
    setScreenOrientation(orientation: 'portrait' | 'landscape') {
      this.screenOrientation = orientation;
    },
    setScreenWidth(width: number) {
      this.screenWidth = width;
    },
    setBackdropVisibility(isVisible: boolean) {
      this.backdrop = isVisible;
    },
    alertValidationError(exception: { message: string; errors: any }) {
      this.alert(exception.message, AlertType.DANGER, 10, exception.errors);
    },
    alertSuccess(
      message: string | LocaleMessages,
      durationInSeconds: number = 10,
      errors?: any
    ) {
      this.alert(message, AlertType.SUCCESS, durationInSeconds, errors);
    },
    alert(
      message: string | LocaleMessages,
      type: AlertType = AlertType.DANGER,
      durationInSeconds: number = 10,
      errors?: any
    ) {
      const isValidationError =
        typeof errors === 'object' && Object.keys(errors).length > 0;
      if (isValidationError) {
        message = `<b>${message}</b>`; // Message principal en gras
        message += alertHelper.extractValidationErrors(errors);
      }
      this.alerts.push({
        type,
        message: message as string,
        countdown: durationInSeconds,
        key: Date.now() + Math.random()
      });
    },
    clearAlert(key: number) {
      const index = this.alerts.findIndex((alert: IAlert) => alert.key === key);
      this.alerts.splice(index, 1);
    },
    setSiteSection(siteSection: SiteSection) {
      this.siteSection = siteSection;
    },
    clearNotifications(sidebarItem: Collection) {
      this.notificationsCount[sidebarItem] = 0;
    },
    openPanel(layout: string[][], panelName: Panel) {
      const panelIndex = layout.findIndex(panels => {
        return panels.indexOf(panelName) >= 0;
      });
      this.openedPanels = this.openedPanels.slice(0, panelIndex);
      this.openedPanels.push(panelName);
    },
    closePanel(panelName: Panel) {
      const panelIndex = this.openedPanels.indexOf(panelName);
      this.openedPanels = this.openedPanels.slice(0, panelIndex);
    },
    addNotification(sidebarItem: Collection) {
      this.notificationsCount[sidebarItem] += 1;
    },
    clearNotificationsCount(sidebarItem: Collection) {
      this.notificationsCount[sidebarItem] = 0;
    },
    reduceSidebar() {
      this.reducedSidebar = !this.reducedSidebar;
    }
  }
});
