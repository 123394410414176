import { defineStore } from 'pinia';
import { getInitialEntityState } from '@/store/common/entities-state';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import { Location } from '@/model/location';
import moment from 'moment';
import { createEntityGetters } from '@/store/common/entities-getters';
import { InputOption } from '@/model/input-option';

const initialEntityState = {
  ...getInitialEntityState<Location>()
};

export const useLocationsStore = defineStore('locations', {
  state: () => initialEntityState,
  getters: {
    ...createEntityGetters<Location>(),
    getLocationsAsSelectOptions(): InputOption[] {
      return this.entities.map(location => {
        return {
          value: location.id + '',
          label: location.name
        };
      });
    },
    getOpenLocations(): Location[] {
      return this.entities.filter(location => {
        if (location.closed_from === null) {
          return true;
        }
        return moment(location.closed_from).isAfter(moment());
      });
    },
    getLocationsWithoutRegion(): Location[] {
      return this.entities.filter(location => {
        return location.region_id === null;
      });
    }
  },
  actions: {
    ...createEntityActions<Location>(Collection.LOCATIONS, initialEntityState),
    getLocationsForRegionId(regionId: number): Location[] {
      return this.entities.filter(location => {
        return location.region_id === regionId;
      });
    }
  }
});
