import { Role } from '@/model/role';

const Employees = () => import('@/components/employees/Employees');

const employeesRoutes = [
  {
    path: '/employees',
    name: 'Employees',
    component: Employees,
    meta: {
      role: Role.STAFF
    }
  }
];

export default employeesRoutes;
