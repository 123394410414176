












import Vue from 'vue';
import HomeFeatures from '@/components/public/HomeFeatures.vue';
import HomePrices from '@/components/public/HomePrices.vue';
import HomeOtherFeatures from '@/components/public/HomeOtherFeatures.vue';
import HomeTimeMoney from '@/components/public/HomeTimeMoney.vue';
import HomeBasicAdvanced from '@/components/public/HomeBasicAdvanced.vue';
import HomeAppointment from '@/components/public/HomeAppointment.vue';
import HomeTitle from '@/components/public/HomeTitle.vue';

export default Vue.extend({
  components: {
    HomeTitle,
    HomeBasicAdvanced,
    HomeTimeMoney,
    HomeFeatures,
    HomeOtherFeatures,
    HomePrices,
    HomeAppointment
  }
});
