import { defineStore } from 'pinia';
import { getInitialEntityState } from '@/store/common/entities-state';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import { createEntityGetters } from '@/store/common/entities-getters';
import { Category } from '@/model/category';
import { InputOption } from '@/model/input-option';

const initialEntityState = {
  ...getInitialEntityState<Category>()
};

export const useCategoriesStore = defineStore('categories', {
  state: () => {
    return initialEntityState;
  },
  getters: {
    ...createEntityGetters<Category>(),
    getAsSelectOptions(): InputOption[] {
      return this.entities.map(location => {
        return {
          value: location.id + '',
          label: location.name
        };
      });
    }
  },
  actions: {
    ...createEntityActions<Category>(Collection.CATEGORIES, initialEntityState)
  }
});
