import apiService from './api.service';
import bookingHelper from '@/helpers/booking.helper';

export default {
  /**
   * Retourne l'historique d'un soin pour un client
   *
   * @param serviceId
   * @param customerId
   * @returns {Promise<*>}
   */
  async getServiceHistoryForCustomer(serviceId, customerId) {
    const response = await apiService.get(
      `events/get-history/${customerId}/${serviceId}`
    );
    return response.data.data;
  },
  /**
   * Retourne les créneaux disponibles pour une date
   *
   * @param locationId
   * @param customerId
   * @param services Objet complet tiré du state (est mappé par le service)
   * @param date
   * @param employeeId
   * @param favoriteEmployeeOnly
   * @param excludeEventsIds
   * @param vendorId
   * @returns {Promise<*>}
   */
  async getTimeSlotsForDate(
    locationId,
    customerId,
    services,
    date,
    employeeId,
    favoriteEmployeeOnly,
    excludeEventsIds = [],
    vendorId
  ) {
    const response = await apiService.post('bookings/get-available-bookings', {
      location_id: locationId,
      customer_id: customerId,
      vendor_id: vendorId,
      services: services.map(service => {
        return {
          id: service.id,
          duration: service.duration
        };
      }),
      date,
      exclude_events_ids: excludeEventsIds,
      employee_id: employeeId,
      favorite_employee_only: favoriteEmployeeOnly
    });
    return response.data.data;
  },
  /**
   * Vérifie qu'une réservation est toujours valable (API vérifie les règles de validation + application)
   *
   * @param customerId
   * @param booking
   * @returns {Promise<*>}
   */
  async verify(customerId, booking) {
    const response = await apiService.post(
      'bookings/verify.json',
      bookingHelper.getBookingBodyForApi(customerId, booking)
    );
    return response.data.data;
  },
  /**
   * Enregistre une nouvelle réservation
   *
   * @param customerId
   * @param booking
   * @returns {Promise<*>}
   */
  async save(customerId, booking) {
    const response = await apiService.post(
      'bookings.json',
      bookingHelper.getBookingBodyForApi(customerId, booking)
    );
    return response.data.data;
  },
  async addAndCloseExpress(customerId, dataForApi) {
    const data = Object.assign({}, { customer_id: customerId }, dataForApi);
    const response = await apiService.post('bookings/express.json', data);
    return response.data.data;
  },
  async edit(bookingId, booking, sendBookingConfirmation) {
    const response = await apiService.put(
      `bookings/${bookingId}/${sendBookingConfirmation}.json`,
      bookingHelper.getBookingBodyForApi(booking.customer_id, booking)
    );
    return response.data.data;
  },
  async close(bookingId, data) {
    const response = await apiService.put(
      `/bookings/close/${bookingId}.json`,
      data
    );
    return response.data.data;
  },
  async getStatsForCustomer(customerId) {
    const response = await apiService.get(
      `/bookings/stats-for-customer/${customerId}.json`
    );
    return response.data.data;
  }
};
