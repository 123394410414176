import { Role } from '@/model/role';

const Locations = () => import('@/components/locations/Locations');

const locationsRoutes = [
  {
    path: '/locations',
    name: 'Locations',
    component: Locations,
    meta: {
      role: Role.ADMIN
    }
  }
];

export default locationsRoutes;
