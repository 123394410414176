import { useLocationsStore } from '@/store/locations-store';
import { useRegionsStore } from '@/store/regions-store';
import { useServicesStore } from '@/store/services-store';
import { useCategoriesStore } from '@/store/categories-store';
import { useMachinesStore } from '@/store/machines-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useMachineModelsStore } from '@/store/machine-models-store';
import { useEmployeesStore } from '@/store/employees-store';
import { useVendorsStore } from '@/store/vendors-store';
import { Collection } from '@/store/common/collections';
import { useClosedDatesStore } from '@/store/closed-dates-store';

export async function getMainCollections(
  vendorId: number,
  force: boolean = false,
  skipCollections: Collection[] = []
): Promise<void> {
  const locationsStore = useLocationsStore();
  const regionsStore = useRegionsStore();
  const servicesStore = useServicesStore();
  const categoriesStore = useCategoriesStore();
  const machinesStore = useMachinesStore();
  const workplacesStore = useWorkplacesStore();
  const machineModelsStore = useMachineModelsStore();
  const employeesStore = useEmployeesStore();
  const vendorsStore = useVendorsStore();
  const closedDatesStore = useClosedDatesStore();
  await Promise.all([
    !skipCollections.includes(Collection.LOCATIONS)
      ? locationsStore.findAll(vendorId, force)
      : null,
    !skipCollections.includes(Collection.REGIONS)
      ? regionsStore.findAll(vendorId, force)
      : null,
    !skipCollections.includes(Collection.SERVICES)
      ? servicesStore.findAll(vendorId, force)
      : null,
    !skipCollections.includes(Collection.CATEGORIES)
      ? categoriesStore.findAll(null, force)
      : null,
    !skipCollections.includes(Collection.MACHINES)
      ? machinesStore.findAll(vendorId, force)
      : null,
    !skipCollections.includes(Collection.MACHINE_MODELS)
      ? machineModelsStore.findAll(vendorId, force)
      : null,
    !skipCollections.includes(Collection.WORKPLACES)
      ? workplacesStore.findAll(vendorId, force)
      : null,
    !skipCollections.includes(Collection.EMPLOYEES)
      ? employeesStore.findAll(null, force)
      : null,
    !skipCollections.includes(Collection.VENDORS)
      ? vendorsStore.findOne(vendorId)
      : null
  ]);
  await Promise.all([
    locationsStore.verifyConfiguration(),
    workplacesStore.verifyConfiguration(),
    servicesStore.verifyConfiguration(),
    machineModelsStore.verifyConfiguration(),
    employeesStore.verifyConfiguration(),
    closedDatesStore.verifyConfiguration(),
    regionsStore.verifyConfiguration()
  ]);
}
