export function camelCaseToSlug(collectionName) {
  return collectionName
    .replace(/[A-Z]/g, s => {
      return '-' + s;
    })
    .toLowerCase();
}

export function camelCaseToUnderscore(collectionName) {
  return collectionName
    .replace(/[A-Z]/g, s => {
      return '_' + s;
    })
    .toLowerCase();
}

export function camelCaseToHumanized(collectionName) {
  return collectionName
    .replace(/[A-Z]/g, s => {
      return ' ' + s;
    })
    .toLowerCase();
}
