import { defineStore } from 'pinia';
import {
  EntityConfigurationErrors,
  getInitialEntityState
} from '@/store/common/entities-state';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import { createEntityGetters } from '@/store/common/entities-getters';
import { Employee } from '@/model/employee';
import i18n from '@/config/i18n';
import { Service } from '@/model/service';
import find from 'lodash/find';
import { useServicesStore } from '@/store/services-store';
import map from 'lodash/map';
import { useVendorsStore } from '@/store/vendors-store';
import { InputOption } from '@/model/input-option';
import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';

const storeId = 'employees';

function sortByName(a: Employee, b: Employee): number {
  return `${a.firstname} ${a.lastname}`.localeCompare(
    `${b.firstname} ${b.lastname}`
  );
}
let initialEntityState = {
  ...getInitialEntityState<Employee>(sortByName)
};
const initialStateClone = cloneDeep(initialEntityState);
if (localStorage.getItem(storeId)) {
  initialEntityState = {
    ...initialEntityState,
    ...JSON.parse(localStorage.getItem(storeId))
  };
}
export const useEmployeesStore = defineStore(storeId, {
  state: () => initialEntityState,
  getters: {
    ...createEntityGetters<Employee>(),
    getAllForServiceAndLocationAsSelectOptions: state => (
      serviceId: number,
      locationId: number | null = null
    ): InputOption[] => {
      return state.entities
        .filter(
          employee =>
            employee.services.some(
              (service: Service) => service.id === serviceId
            ) &&
            (!locationId ||
              employee.workplaces.some(
                workplace => workplace.location_id === locationId
              ))
        )
        .map(employee => ({
          value: employee.id,
          label: employee.firstname + ' ' + employee.lastname
        }));
    },
    getAllAsSelectOptions: state => (): InputOption[] => {
      return state.entities
        .filter(employee => employee.timetable)
        .map(employee => {
          return {
            label: employee.firstname + ' ' + employee.lastname,
            value: employee.id
          };
        });
    },
    getAllWithTimetablesEnabled: state => (): Employee[] => {
      return state.entities.filter(employee => {
        return employee.timetable;
      });
    }
  },
  actions: {
    ...createEntityActions<Employee>(Collection.EMPLOYEES, initialEntityState),
    verifyConfiguration() {
      const employeesErrors: EntityConfigurationErrors[] = [];
      this.entities.forEach((employee: Employee) => {
        const entityErrors: EntityConfigurationErrors = {
          id: employee.id,
          errors: []
        };
        // On ne gère pas les employés qui n'ont pas d'horaires
        if (employee.timetable) {
          const vendorsStore = useVendorsStore();
          const servicesStore = useServicesStore();
          // Assignation par rapport à un espace de travail + service au minimum
          if (employee.services.length === 0) {
            entityErrors.errors.push(i18n.t('configError.employeeServices'));
          }
          if (
            employee.workplaces.length === 0 &&
            vendorsStore.currentEntity.workplaces_enabled
          ) {
            entityErrors.errors.push(i18n.t('configError.employeeWorkplaces'));
          }
          if (vendorsStore.currentEntity.workplaces_enabled) {
            // Chaque service que l'employé effectue doit être effectuable dans une de ses salles assignées
            employee.services.forEach((service: Service) => {
              const serviceWithAssociatedWorkplaces = find(
                servicesStore.entities,
                { id: service.id }
              );
              const ok =
                serviceWithAssociatedWorkplaces &&
                serviceWithAssociatedWorkplaces.workplaces.some(workplace => {
                  return (
                    map(employee.workplaces, 'id').indexOf(workplace.id) >= 0
                  );
                });
              if (!ok) {
                entityErrors.errors.push(
                  i18n.t('configError.employeeServicesWorkplaces')
                );
              }
            });
          }
          /**
           * TODO : a des horaires dans un lieu où aucun de ses services ne peut être effectué (il faudra refactor employees module et tout passer dans resources module pour être propre) => on que l'horaire de l'employé courant
           */
        }
        if (entityErrors.errors.length > 0) {
          employeesErrors.push(entityErrors);
        }
      });

      this.setConfigurationErrors(employeesErrors);
    },
    reset() {
      Vue.set(this, '$state', cloneDeep(initialStateClone));
    }
  }
});
