import moment, { Moment } from 'moment';
import { Booking } from '@/model/booking';

export class MomentHelper {
  static getBookingStaffCustomerTimeDiff(booking: Partial<Booking>): number {
    return moment(booking.end).diff(moment(booking.customer_end), 'minutes');
  }

  static checkIfTimeRangesOverlap(
    startA: Moment,
    endA: Moment,
    startB: Moment,
    endB: Moment,
    inclusive = false
  ): boolean {
    return inclusive
      ? startA.isSameOrBefore(endB) && endA.isSameOrAfter(startB)
      : startA.isBefore(endB) && endA.isAfter(startB);
  }
}
