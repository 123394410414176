import apiService from '@/services/api.service';
import { serialize } from 'object-to-formdata';
import { Vendor } from '@/model/vendor';
import { VendorFormData } from '@/model/vendor-form-data';
import { VendorSettingsPage } from '@/model/vendor-settings-page';

export default {
  async edit(
    vendorData: Vendor | VendorFormData,
    validate: VendorSettingsPage
  ): Promise<Vendor> {
    return (
      await apiService.put(
        `vendors/${vendorData.id}`,
        serialize(
          { vendor: vendorData, validate },
          {
            nullsAsUndefineds: true,
            booleansAsIntegers: true,
            allowEmptyArrays: true,
            indices: true
          }
        )
      )
    ).data.data;
  },
  async getById(vendorId: number): Promise<Vendor> {
    return (await apiService.get(`vendors/${vendorId}`)).data.data;
  },
  /**
   *
   * @param {{name, id}} query
   * @param {string} query.q
   * @param {string} query.id
   * @param {string} query.name
   * @param {string} query.email
   * @returns {Promise<AxiosResponse<any>|undefined>}
   */
  async findAll(query = {}) {
    let queryText = '';
    Object.keys(query).forEach((q, index) => {
      if (query[q] && query[q] !== '') {
        if (index > 0) {
          queryText += '&';
        }
        queryText += `${q}=${query[q]}`;
      }
    });
    return (await apiService.get('vendors.json?' + queryText)).data.data;
  },
  async create(vendorData) {
    return (
      await apiService.post(
        'vendors.json',
        serialize(vendorData, {
          nullsAsUndefineds: true,
          booleansAsIntegers: true,
          allowEmptyArrays: true,
          indices: true
        })
      )
    ).data.data;
  },
  async userList(userId: number) {
    return (await apiService.get(`vendors/user-list/${userId}`)).data.data;
  },

  async getSlackState(vendor: VendorFormData): Promise<string> {
    return (await apiService.get(`vendors/slack-state/${vendor.id}`)).data.data;
  },
  async revokingSlack(vendor: VendorFormData): Promise<Vendor> {
    return (await apiService.get(`vendors/slack-revoke/${vendor.id}`)).data
      .data;
  },
  async getRemainingCredits(vendorId: number): Promise<number> {
    return (
      await apiService.get(
        `short-messages/get-remaining-credits.json?vendor_id=${vendorId}`
      )
    ).data.data.remainingCredits;
  }
};
