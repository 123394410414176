














































































import Navbar from '@/components/Navbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import Sidebar from '@/components/Sidebar.vue';
import * as Sentry from '@sentry/vue';
import { IAlert, SiteSection, useUiStore } from '@/store/ui-store';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { useLocationsStore } from '@/store/locations-store';
import Vue from 'vue';
import { useEmployeesStore } from '@/store/employees-store';
import { useServicesStore } from '@/store/services-store';
import { useTimetablesStore } from '@/store/timetables-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useMachineModelsStore } from './store/machine-models-store';
import { Collection } from '@/store/common/collections';
import Alert from '@/components/shared/Alert.vue';
import { useCalendarStore } from '@/store/calendar-store';
import pick from 'lodash/pick';
import { useClosedDatesStore } from '@/store/closed-dates-store';

export default Vue.extend({
  name: 'App',
  components: {
    Navbar,
    AppFooter,
    Sidebar,
    Alert
  },
  data() {
    return {
      SITE_SECTION: SiteSection
    };
  },
  computed: {
    ...mapStores(
      useUiStore,
      useUsersStore,
      useLocationsStore,
      useEmployeesStore,
      useMachineModelsStore,
      useServicesStore,
      useTimetablesStore,
      useWorkplacesStore,
      useClosedDatesStore,
      useCalendarStore
    ),
    alerts(): IAlert[] {
      return this.uiStore.alerts;
    },
    siteSection(): SiteSection {
      return this.uiStore.siteSection;
    },
    language(): string {
      return this.usersStore.user.language;
    },
    isLoggedIn(): boolean {
      return this.usersStore.isLoggedIn;
    },
    loggedInEmployeeId(): number {
      return this.usersStore.loggedInEmployeeId;
    },
    loggedInUserId(): number {
      return this.usersStore.loggedInUserId;
    },
    backdrop(): boolean {
      return this.uiStore.backdrop;
    },
    mobileLandscape(): boolean {
      return this.uiStore.mobileLandscape;
    },
    calendarPage(): boolean {
      return this.$route.path.includes('agenda');
    },
    reducedSidebar(): boolean {
      return this.uiStore.reducedSidebar;
    }
  },
  created() {
    this.persistStoreInLocalStorage();
    if (this.isLoggedIn) {
      if (this.isStaffOrMore) {
        this.usersStore.loadDataForStaffUser();
      }
      this.usersStore.getUser(this.loggedInUserId).then(user => {
        Sentry.setUser({ username: user.username || user.email });
      });
    }
    if (this.language) {
      this.setLanguage(this.language);
    } else {
      // Détection langue du navigateur si première visite sur le navigateur
      this.setLanguage(navigator.language.slice(0, 2));
    }
    setTimeout(() => {
      // Ne pas lancer trop tôt, avant que les connections soient chargées, à améliorer...
      this.checkVendorConfiguration();
    }, 5000);
    this.handleRedirectWhenLoggingOut();
  },
  mounted() {
    const portraitMode = window.matchMedia('(orientation:portrait)');
    portraitMode.addEventListener('change', this.handleScreenOrientationChange);
    this.handleScreenOrientationChange();
    this.uiStore.setScreenWidth(window.screen.width);
    window.addEventListener('resize', this.handleScreenResize);
  },
  methods: {
    setVh() {
      // Règle le problème de vh dans les mobiles
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    handleScreenOrientationChange() {
      const portraitMode = window.matchMedia('(orientation:portrait)');
      this.setVh();
      this.uiStore.setScreenOrientation(
        portraitMode.matches ? 'portrait' : 'landscape'
      );
    },
    handleScreenResize() {
      this.setVh();
      this.uiStore.setScreenWidth(window.screen.width);
    },
    persistStoreInLocalStorage() {
      if (localStorage.getItem('vuex') !== null) {
        localStorage.removeItem('vuex');
      }
      this.usersStore.$subscribe((mutation, state) => {
        localStorage.setItem('users', JSON.stringify(state));
      });
      const calendarStore = useCalendarStore();
      calendarStore.$onAction(({ name, after }) => {
        const actionsToWatch: string[] = [
          'setViewType',
          'setViewMode',
          'setDisplayType',
          'changeMonth',
          'setStartOfDay',
          'setZoom',
          'selectDate'
        ];
        if (actionsToWatch.includes(name)) {
          after(() => {
            localStorage.setItem(
              'calendar',
              JSON.stringify(
                pick(calendarStore, [
                  'viewType',
                  'viewMode',
                  'previousDisplayType',
                  'displayType',
                  'startOfDay',
                  'zoom',
                  'selectedDate',
                  'selectedMonth'
                ])
              )
            );
          });
        }
      });
      const employeesStore = useEmployeesStore();
      employeesStore.$onAction(({ name, after }) => {
        const actionsToWatch: string[] = ['setCurrentEntity'];
        if (actionsToWatch.includes(name)) {
          after(() => {
            localStorage.setItem(
              'employees',
              JSON.stringify(pick(employeesStore, ['currentEntity']))
            );
          });
        }
      });
      const timetablesStore = useTimetablesStore();
      timetablesStore.$onAction(({ name, after }) => {
        const actionsToWatch: string[] = ['setViewType'];
        if (actionsToWatch.includes(name)) {
          after(() => {
            localStorage.setItem(
              'timetables',
              JSON.stringify(pick(timetablesStore, ['viewType']))
            );
          });
        }
      });
    },
    getNotificationsCount(sidebarItem: Collection): number {
      return this.uiStore.getNotificationsCount(sidebarItem);
    },
    clearAlert(key: number) {
      this.uiStore.clearAlert(key);
    },
    setLanguage(language: string) {
      this.usersStore.setLanguage(language);
    },
    checkVendorConfiguration() {
      [
        this.locationsStore,
        this.workplacesStore,
        this.servicesStore,
        this.machineModelsStore,
        this.employeesStore,
        this.closedDatesStore
      ].forEach(store =>
        store.$onAction(({ name, after }) => {
          if (name === 'findAll') {
            after(() => {
              store.verifyConfiguration();
            });
          }
        })
      );
    },
    handleRedirectWhenLoggingOut() {
      this.usersStore.$onAction(({ name, args, after }) => {
        if (name === 'logout') {
          after(() => {
            this.$router.replace({
              name: 'Home'
            });
          });
        }
      });
    },
    reduceSidebar() {
      this.uiStore.reduceSidebar();
    }
  }
});
