import { defineStore } from 'pinia';
import {
  EntityConfigurationErrors,
  getInitialEntityState
} from '@/store/common/entities-state';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import { createEntityGetters } from '@/store/common/entities-getters';
import { Service } from '@/model/service';
import filter from 'lodash/filter';
import i18n from '@/config/i18n';
import { useVendorsStore } from '@/store/vendors-store';
import servicesService from '@/services/services.service';
import * as Sentry from '@sentry/vue';
import { useUiStore } from '@/store/ui-store';

const initialEntityState = {
  ...getInitialEntityState<Service>(),
  sortPerformedOrder: false
};

export const useServicesStore = defineStore('services', {
  state: () => {
    return initialEntityState;
  },
  getters: {
    ...createEntityGetters<Service>(),
    getChosenLocationServices: state => (locationId: number): Service[] => {
      return filter(state.entities, (service: Service) => {
        let x = false;
        service.workplaces.forEach(workplace => {
          x = x ? true : workplace.location_id === locationId;
        });
        return x;
      });
    },
    getServicesForCategoryId: state => (categoryId: number): Service[] => {
      return state.entities.filter(service => {
        return service.category.id === categoryId;
      });
    }
  },
  actions: {
    ...createEntityActions<Service>(Collection.SERVICES, initialEntityState),
    verifyConfiguration() {
      const servicesErrors: EntityConfigurationErrors[] = [];
      this.entities.forEach((service: Service) => {
        const entityErrors: EntityConfigurationErrors = {
          id: service.id,
          errors: []
        };
        if (
          useVendorsStore().currentEntity.workplaces_enabled &&
          service.workplaces.length === 0
        ) {
          entityErrors.errors.push(i18n.t('configError.serviceWorkplaces'));
        }
        if (service.employees.length === 0) {
          entityErrors.errors.push(i18n.t('configError.serviceEmployees'));
        }
        if (entityErrors.errors.length > 0) {
          servicesErrors.push(entityErrors);
        }
      });

      this.setConfigurationErrors(servicesErrors);
    },
    async updateServicesOrder(serviceIds: number[]) {
      const uiStore = useUiStore();
      try {
        this.fetching = true;
        const sortedServices = [];
        for (const [index, serviceId] of serviceIds.entries()) {
          sortedServices.push({ id: serviceId, performed_order: index });
        }
        await servicesService.sortServices(sortedServices);
        this.fetching = false;
        this.sortPerformedOrder = false;
      } catch (exception) {
        console.error(exception);
        this.failure = true;
        this.fetching = false;
        uiStore.alert(exception.message);
        Sentry.captureException(exception);
      }
    },
    toggleSortPerformedOrder(value: boolean) {
      this.sortPerformedOrder = value;
    }
  }
});
