import { defineStore } from 'pinia';
import {
  EntityConfigurationErrors,
  getInitialEntityState
} from '@/store/common/entities-state';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import { ClosedDate } from '@/model/closed-date';
import { createEntityGetters } from '@/store/common/entities-getters';
import i18n from '@/config/i18n';
const initialEntityState = {
  ...getInitialEntityState<ClosedDate>()
};

export const useClosedDatesStore = defineStore('closedDates', {
  state: () => initialEntityState,
  getters: {
    ...createEntityGetters<ClosedDate>()
  },
  actions: {
    ...createEntityActions<ClosedDate>(
      Collection.CLOSED_DATES,
      initialEntityState
    ),
    verifyConfiguration() {
      const closedDatesErrors: EntityConfigurationErrors[] = [];
      this.entities.forEach((closedDate: ClosedDate) => {
        const entityErrors: EntityConfigurationErrors = {
          id: closedDate.id,
          errors: []
        };
        if (closedDate.locations.length === 0) {
          entityErrors.errors.push(i18n.t('configError.closedDateLocations'));
        }
        if (entityErrors.errors.length > 0) {
          closedDatesErrors.push(entityErrors);
        }
      });

      this.setConfigurationErrors(closedDatesErrors);
    }
  }
});
