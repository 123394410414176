import ResourceConfigurationAlert from '@/components/shared/ResourceConfigurationAlert.vue';
import Vue from 'vue';
import { Collection } from '@/store/common/collections';
import { mapStores } from 'pinia';
import { useVendorsStore } from '@/store/vendors-store';
import { Vendor } from '@/model/vendor';
import { useEmployeesStore } from '@/store/employees-store';
import { useMachinesStore } from '@/store/machines-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useServicesStore } from '@/store/services-store';
import { useClosedDatesStore } from '@/store/closed-dates-store';
import { useRegionsStore } from '@/store/regions-store';

export default Vue.extend({
  components: { ResourceConfigurationAlert },
  data() {
    return {
      COLLECTION: Collection
    };
  },
  computed: {
    ...mapStores(
      useVendorsStore,
      useEmployeesStore,
      useMachinesStore,
      useWorkplacesStore,
      useServicesStore,
      useClosedDatesStore,
      useRegionsStore
    ),
    vendor(): Vendor {
      return this.vendorsStore.currentEntity;
    }
  },
  methods: {
    hasConfigurationErrors(
      collectionName: Collection,
      entityId?: number
    ): boolean {
      switch (collectionName) {
        case Collection.EMPLOYEES:
          return this.employeesStore.hasConfigurationErrors(entityId);
        case Collection.SERVICES:
          return this.servicesStore.hasConfigurationErrors(entityId);
        case Collection.WORKPLACES:
          return this.workplacesStore.hasConfigurationErrors(entityId);
        case Collection.MACHINES:
          return this.machinesStore.hasConfigurationErrors(entityId);
        case Collection.CLOSED_DATES:
          return this.closedDatesStore.hasConfigurationErrors(entityId);
        case Collection.REGIONS:
          return this.regionsStore.hasConfigurationErrors(entityId);
        default:
          return false;
      }
    },
    getConfigurationErrors(
      collectionName: Collection,
      entityId: number
    ): string {
      switch (collectionName) {
        case Collection.EMPLOYEES:
          return this.employeesStore.getConfigurationErrors(entityId);
        case Collection.SERVICES:
          return this.servicesStore.getConfigurationErrors(entityId);
        case Collection.WORKPLACES:
          return this.workplacesStore.getConfigurationErrors(entityId);
        case Collection.MACHINES:
          return this.machinesStore.getConfigurationErrors(entityId);
        case Collection.CLOSED_DATES:
          return this.closedDatesStore.getConfigurationErrors(entityId);
        case Collection.REGIONS:
          return this.regionsStore.getConfigurationErrors(entityId);
        default:
          return '';
      }
    }
  }
});
