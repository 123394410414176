












































import Vue from 'vue';
import PlainButton from '@/components/shared/PlainButton.vue';

export default Vue.extend({
  components: {
    PlainButton
  }
});
