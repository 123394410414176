import phone from './phone';
import date from './date';
import weekday from './weekday';
import time from './time';
import weeezy from '@/filters/weeezy';
import currency from './currency';

export default {
  install(Vue) {
    Vue.filter('phone', phone);
    Vue.filter('date', date);
    Vue.filter('weekday', weekday);
    Vue.filter('time', time);
    Vue.filter('weeezy', weeezy);
    Vue.filter('currency', currency);
  }
};
