var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative tw-flex-grow tw-flex tw-flex-col tw-justify-between tw-py-4 wz-pb-ios tw-z-0 xl:tw-overflow-hidden"},[_c('div',[_c('div',{staticClass:"xl:tw-px-3"},[(_vm.isStaffOrMore)?_c('router-link',{staticClass:"tw-flex tw-justify-between tw-items-center tw-gap-4 tw-bg-primary-500 tw-text-white tw-font-bold tw-rounded-md hover:tw-no-underline hover:tw-text-white",class:[_vm.reducedSidebar ? 'tw-px-2 tw-py-2' : 'tw-px-4 tw-py-2'],attrs:{"to":{ name: 'AddBooking' }}},[_c('div',{staticClass:"tw-flex-none tw-inline-flex tw-justify-center tw-items-center tw-w-8"},[_c('i',{staticClass:"fas fa-lg fa-plus"})]),_c('span',{staticClass:"tw-flex-grow tw-flex-shrink-0 tw-inline-block tw-transform tw-transition-all tw-duration-1000",class:[_vm.reducedSidebar ? 'tw-translate-x-96' : 'tw-translate-x-0']},[_vm._v(_vm._s(_vm.$t('booking.titleAdd')))])]):_vm._e()],1),_c('hr'),_c('div',{staticClass:"scrollbar-thin xl:scroll-height xl:tw-pb-4 xl:tw-px-3 xl:tw-overflow-y-auto"},[_c('sidebar-title',[_vm._v(" "+_vm._s(_vm.$t('sidebar.calendar'))+" ")]),(_vm.isStaffOrMore)?_c('sidebar-item',{attrs:{"title":_vm.$t('sidebar.employees'),"to":{
          name: 'Calendar',
          query: { viewType: _vm.CalendarViewType.EMPLOYEE }
        },"svg":"calendar_employee"}}):_vm._e(),(_vm.isStaffOrMore && _vm.workplacesCount > 0)?_c('sidebar-item',{attrs:{"title":_vm.$tc('sidebar.workplaces', 2),"to":{
          name: 'Calendar',
          query: { viewType: _vm.CalendarViewType.WORKPLACE }
        },"svg":"calendar_workplace"}}):_vm._e(),(_vm.isStaffOrMore && _vm.machinesCount > 0)?_c('sidebar-item',{attrs:{"title":_vm.$tc('sidebar.resources', 2),"to":{
          name: 'Calendar',
          query: { viewType: _vm.CalendarViewType.MACHINE }
        },"svg":"calendar_machine"}}):_vm._e(),_c('hr'),_c('sidebar-title',[_vm._v(" "+_vm._s(_vm.$t('sidebar.customers'))+" ")]),(_vm.isStaffOrMore)?_c('sidebar-item',{attrs:{"title":_vm.$t('sidebar.customers'),"to":{ name: 'Customers' },"icon":"users"}}):_vm._e(),(_vm.isStaffOrMore)?_c('sidebar-item',{attrs:{"title":_vm.$t('sidebar.giftCards'),"to":{ name: 'Vouchers' },"icon":"gift"}}):_vm._e(),(_vm.isAdmin)?_c('div',[_c('hr'),_c('sidebar-title',[_vm._v(" "+_vm._s(_vm.$t('sidebar.reports'))+" ")]),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"title":_vm.$t('sidebar.cashClosing'),"to":{ name: 'CashClosing' },"icon":"cash-register"}}):_vm._e()],1):_vm._e(),_c('hr'),(_vm.isAdmin || _vm.canEditTimetables)?_c('sidebar-title',[_vm._v(" "+_vm._s(_vm.$t('sidebar.planning'))+" ")]):_vm._e(),(_vm.isAdmin || _vm.canEditTimetables)?_c('sidebar-item',{attrs:{"title":_vm.$t('sidebar.schedules'),"to":{ name: 'Timetables' },"icon":"clock"}}):_vm._e(),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"title":_vm.$t('sidebar.closedDates'),"to":{
          name: 'ClosedDates',
          query: { notification: _vm.COLLECTION.CLOSED_DATES }
        },"icon":"store-alt-slash","collection":_vm.COLLECTION.CLOSED_DATES}}):_vm._e(),(_vm.isAdmin || _vm.canEditTimetables)?_c('hr'):_vm._e(),_c('sidebar-title',[_vm._v(" "+_vm._s(_vm.$t('sidebar.config'))+" ")]),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"title":_vm.$t('sidebar.employees'),"to":{
          name: 'Employees',
          query: { notification: _vm.COLLECTION.EMPLOYEES }
        },"icon":"user-friends","collection":_vm.COLLECTION.EMPLOYEES}}):_vm._e(),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"title":_vm.$t('sidebar.services'),"to":{
          name: 'Services',
          query: { notification: _vm.COLLECTION.SERVICES }
        },"icon":"concierge-bell","collection":_vm.COLLECTION.SERVICES}}):_vm._e(),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"title":_vm.$tc('sidebar.workplaces', 2),"to":{
          name: 'Workplaces',
          query: { notification: _vm.COLLECTION.WORKPLACES }
        },"icon":"door-open","collection":_vm.COLLECTION.WORKPLACES}}):_vm._e(),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"title":_vm.$tc('sidebar.sharedResources', 2),"to":{
          name: 'Machines',
          query: { notification: _vm.COLLECTION.MACHINES }
        },"icon":"briefcase","collection":_vm.COLLECTION.MACHINES}}):_vm._e(),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"title":_vm.$t('sidebar.locations'),"to":{
          name: 'Locations',
          query: { notification: _vm.COLLECTION.LOCATIONS }
        },"icon":"building","collection":_vm.COLLECTION.LOCATIONS}}):_vm._e(),(_vm.isAdmin && _vm.isFromLPittet)?_c('sidebar-item',{attrs:{"title":_vm.$t('sidebar.regions'),"to":{ name: 'Regions', query: { notification: _vm.COLLECTION.REGIONS } },"icon":"map","collection":_vm.COLLECTION.REGIONS}}):_vm._e(),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"title":_vm.$t('navbar.settings'),"to":{ name: 'VendorLegalInformationForm' },"icon":"sliders-v"}}):_vm._e(),(_vm.isStaffOrMore)?_c('sidebar-item',{attrs:{"title":_vm.$t('navbar.myAccount'),"to":{ name: 'MyAccountEmployeeEdit' },"icon":"user-circle"}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }