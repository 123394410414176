import { defineStore } from 'pinia';
import {
  EntitiesState,
  EntityConfigurationErrors,
  getInitialEntityState
} from '@/store/common/entities-state';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import { createEntityGetters } from '@/store/common/entities-getters';
import { MachineModel } from '@/model/machine-model';
import i18n from '@/config/i18n';
import { useMachinesStore } from '@/store/machines-store';
import { useVendorsStore } from '@/store/vendors-store';
import { Machine } from '@/model/machine';
import Vue from 'vue';

const initialEntityState = {
  ...getInitialEntityState<MachineModel>()
};
const state: EntitiesState<MachineModel> & {
  machineModelIdToAdd?: number | null;
} = initialEntityState;
state.machineModelIdToAdd = null;

export const useMachineModelsStore = defineStore('machineModels', {
  state: () => state,
  getters: {
    ...createEntityGetters<MachineModel>(),
    getAsSelectOptions: state => {
      return state.entities.map(location => {
        return {
          value: location.id + '',
          label: location.name
        };
      });
    }
  },
  actions: {
    ...createEntityActions<MachineModel>(Collection.MACHINE_MODELS, state),
    setMachineModelIdToAdd(machineModelId: number | null) {
      this.machineModelIdToAdd = machineModelId;
    },
    addMachineToHisModelInStore(machine: Machine) {
      const index: number = this.entities.findIndex(
        (currentModel: MachineModel) => {
          return machine.machine_model_id === currentModel.id;
        }
      );
      this.entities[index].machines.push(machine);
    },
    editMachineFromHisModelInStore(machine: Machine) {
      const machineModelIndex: number = this.entities.findIndex(
        (loopModel: MachineModel) => {
          return machine.machine_model_id === loopModel.id;
        }
      );
      const machineIndex: number = this.entities[
        machineModelIndex
      ].machines.findIndex((loopMachine: Machine) => {
        return machine.id === loopMachine.id;
      });
      Vue.set(this.entities[machineModelIndex].machines, machineIndex, machine);
    },
    removeMachineFromHisModelInStore(machine: Machine) {
      const machineModelIndex: number = this.entities.findIndex(
        (loopModel: MachineModel) => {
          return machine.machine_model_id === loopModel.id;
        }
      );
      const machineIndex: number = this.entities[
        machineModelIndex
      ].machines.findIndex((loopMachine: Machine) => {
        return machine.id === loopMachine.id;
      });
      Vue.delete(this.entities[machineModelIndex].machines, machineIndex);
    },
    verifyConfiguration() {
      const machinesStore = useMachinesStore();
      const vendorsStore = useVendorsStore();
      const machinesErrors: EntityConfigurationErrors[] = [];
      if (vendorsStore.currentEntity.machines_enabled) {
        this.entities.forEach((machineModel: MachineModel) => {
          machineModel.machines.forEach(machine => {
            const entityErrors: EntityConfigurationErrors = {
              id: machine.id,
              errors: []
            };
            if (machineModel.services.length === 0) {
              entityErrors.errors.push(i18n.t('configError.machineServices'));
            }
            if (entityErrors.errors.length > 0) {
              machinesErrors.push(entityErrors);
            }
          });
        });
      }
      machinesStore.setConfigurationErrors(machinesErrors);
    }
  }
});
