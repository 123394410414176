



















































import Vue, { PropType, VueConstructor } from 'vue';
import { Collection } from '@/store/common/collections';
import { TranslateResult } from 'vue-i18n';
import { mapStores } from 'pinia';
import { useUiStore } from '@/store/ui-store';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import { Location } from 'vue-router/types/router';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ResourceConfigMixin>
>).extend({
  mixins: [ResourceConfigMixin],
  props: {
    collection: {
      type: String as PropType<Collection | null>,
      required: false,
      default: null
    },
    icon: {
      type: String as PropType<string | null>,
      default: null
    },
    svg: {
      type: String as PropType<string | null>,
      default: null
    },
    to: {
      type: Object as PropType<string | Location>,
      required: true
    },
    title: {
      type: String as PropType<TranslateResult>,
      required: true
    }
  },
  data() {
    return {
      hover: false
    };
  },
  computed: {
    ...mapStores(useUiStore),
    reducedSidebar(): boolean {
      return this.uiStore.reducedSidebar;
    }
  },
  methods: {
    getNotificationsCount(collection) {
      return this.uiStore.getNotificationsCount(collection);
    }
  }
});
