import { Role } from '@/model/role';
const BookingConfirmation = () =>
  import('@/components/bookings/BookingConfirmation');
const AddBooking = () => import('@/components/bookings/AddBooking');
const PublicBooking = () => import('@/components/bookings/PublicBooking');

const bookingsRoutes = [
  {
    path: '/bookings/public/:vendorId/:locationId?',
    name: 'PublicBooking',
    component: PublicBooking,
    meta: {
      public: true
    }
  },
  {
    path: '/bookings/add',
    name: 'AddBooking',
    component: AddBooking,
    meta: {
      role: Role.STAFF
    }
  },
  {
    path: '/bookings/edit',
    name: 'EditBooking',
    component: AddBooking,
    meta: {
      role: Role.STAFF
    }
  },
  {
    path: '/customers/view/:customerId/booking/confirmation',
    name: 'BookingConfirmation',
    component: BookingConfirmation,
    meta: {
      role: Role.CUSTOMER
    }
  }
];

export default bookingsRoutes;
