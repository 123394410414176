























import Vue from 'vue';

export default Vue.extend({
  props: {
    iconLink: {
      type: String,
      default: null
    },
    star: {
      type: Boolean,
      default: false
    },
    shadow: {
      default: 'yellow',
      validator(value: any) {
        return value === 'yellow' || value === 'box';
      }
    }
  }
});
