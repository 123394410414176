var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-h-screen-navbar xl:tw-h-auto tw-flex-col xl:tw-flex-row tw-items-center tw-justify-center xl:tw-justify-end"},[_c('router-link',{staticClass:"tw-my-3 xl:tw-my-0 tw-mx-3 tw-px-2 tw-py-1 tw-border tw-border-dark tw-text-dark tw-font-bold tw-uppercase",attrs:{"to":"/"}},[_vm._v(" "+_vm._s(_vm.$t('navbar.bookNow'))+" ")]),(!_vm.isLoggedIn)?_c('router-link',{staticClass:"tw-my-3 xl:tw-my-0 btn btn-white",attrs:{"to":{ name: 'Login' },"exact":""}},[_c('i',{staticClass:"fas fa-user mr-2 d-none d-md-inline-block"}),_vm._v(" "+_vm._s(_vm.$t('navbar.login'))+" ")]):_vm._e(),(
      _vm.isLoggedIn &&
        _vm.isCustomer &&
        _vm.$route.meta.public &&
        _vm.$route.name !== 'MyAccount'
    )?_c('router-link',{staticClass:"tw-my-3 xl:tw-my-0 btn btn-primary",attrs:{"to":{
      name: 'MyAccount',
      query: { viewType: _vm.CalendarResourceType.EMPLOYEE }
    },"exact":""}},[_vm._v(" "+_vm._s(_vm.$t('navbar.myAccount'))+" "),_c('i',{staticClass:"fas fa-arrow-right ml-2"})]):_vm._e(),(_vm.isLoggedIn && _vm.isCustomer && _vm.$route.name === 'MyAccount')?_c('current-user',{attrs:{"direction":"down"}}):_vm._e(),(_vm.isLoggedIn && _vm.isStaffOrMore && _vm.$route.meta.public)?_c('router-link',{staticClass:"tw-my-3 xl:tw-my-0 btn btn-primary",attrs:{"to":{
      name: 'Calendar',
      query: { viewType: _vm.CalendarResourceType.EMPLOYEE }
    },"exact":""}},[_vm._v(" "+_vm._s(_vm.$t('navbar.admin'))+" "),_c('i',{staticClass:"fas fa-arrow-right ml-2"})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }