import Vue from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';

export default Vue.extend({
  computed: {
    ...mapStores(useUsersStore),
    isAdmin(): boolean {
      return this.usersStore.isAdmin;
    },
    isStaff(): boolean {
      return this.usersStore.isStaff;
    },
    isStaffOrMore(): boolean {
      return this.usersStore.isStaffOrMore;
    },
    isCustomerOrMore(): boolean {
      return this.usersStore.isCustomerOrMore;
    },
    isCustomer(): boolean {
      return this.usersStore.isCustomer;
    },
    isGuest(): boolean {
      return this.usersStore.isGuest;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    }
  }
});
