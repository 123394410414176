import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default function(phoneNumber) {
  if (!phoneNumber) {
    return null;
  }
  const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);
  if (phoneNumberObject) {
    if (phoneNumberObject.country === 'CH') {
      return phoneNumberObject.formatNational();
    } else {
      return phoneNumber;
    }
  }
}
