























































































































































































































import { Collection } from '@/store/common/collections';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useUiStore } from '@/store/ui-store';
import { useUsersStore } from '@/store/users-store';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import { CalendarResourceType } from '@/store/calendar-store';
import SidebarItem from '@/components/shared/SidebarItem.vue';
import SidebarTitle from '@/components/shared/SidebarTitle.vue';
import { useMachinesStore } from '@/store/machines-store';
import { useWorkplacesStore } from '@/store/workplaces-store';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ResourceConfigMixin>
>).extend({
  components: { SidebarItem, SidebarTitle },
  mixins: [ResourceConfigMixin],
  data() {
    return {
      COLLECTION: Collection,
      CalendarViewType: CalendarResourceType
    };
  },
  computed: {
    ...mapStores(
      useUiStore,
      useUsersStore,
      useWorkplacesStore,
      useMachinesStore
    ),
    loggedInEmployeeId(): number {
      return this.usersStore.loggedInEmployeeId;
    },
    canEditTimetables(): boolean {
      return this.usersStore.user.entity.employee?.can_set_timetable;
    },
    reducedSidebar(): boolean {
      return this.uiStore.reducedSidebar;
    },
    workplacesCount(): number {
      return this.workplacesStore.entities.length;
    },
    machinesCount(): number {
      return this.machinesStore.entities.length;
    }
  },
  created() {
    this.$router.afterEach(to => {
      if (
        to.query.notification !== undefined &&
        this.getNotificationsCount(to.query.notification)
      ) {
        this.clearNotifications(to.query.notification);
      }
    });
  },
  methods: {
    getNotificationsCount(collection) {
      return this.uiStore.getNotificationsCount(collection);
    },
    clearNotifications(sideBarItem) {
      this.uiStore.clearNotificationsCount(sideBarItem);
    }
  }
});
