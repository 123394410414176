import moment from 'moment';

moment.updateLocale('fr', {
  week: {
    dow: 1,
    doy: 4
  },
  longDateFormat: {
    LT: 'HH [h] mm',
    LL: 'dddd DD MMMM YYYY'
  }
});

moment.updateLocale('en', {
  longDateFormat: {
    LT: 'h:mm a',
    LL: 'dddd, MMMM D, YYYY'
  }
});
