import { defineStore } from 'pinia';
import {
  EntitiesState,
  getInitialEntityState
} from '@/store/common/entities-state';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import { createEntityGetters } from '@/store/common/entities-getters';
import { Vendor } from '@/model/vendor';
import vendorsService from '@/services/vendors.service.ts';
import { useUiStore } from '@/store/ui-store';
import { VendorSettingsPage } from '@/model/vendor-settings-page';
import { VendorFormData } from '@/model/vendor-form-data';
import cloneDeep from 'lodash/cloneDeep';
import { useUsersStore } from '@/store/users-store';
import i18n from '@/config/i18n';

const newVendorBaseState: VendorFormData = {
  publicAddressSameAsLegal: true,
  actual_free_time_slot_first_booking: 30,
  available_bookings_interval: 15,
  booking_slots_proposition_interval: 15,
  client_exit_duration_in_minutes: 5,
  cancellation_notice: 24,
  email_default_language: 'fr',
  pictureFile: null as File | null,
  resources: {
    location: {},
    employee: {
      weekdays: [
        {
          weekday: 1,
          available: true,
          time_ranges: [
            { start: '08:00', end: '12:00' },
            { start: '13:00', end: '17:00' }
          ]
        },
        {
          weekday: 2,
          available: true,
          time_ranges: [
            { start: '08:00', end: '12:00' },
            { start: '13:00', end: '17:00' }
          ]
        },
        {
          weekday: 3,
          available: true,
          time_ranges: [
            { start: '08:00', end: '12:00' },
            { start: '13:00', end: '17:00' }
          ]
        },
        {
          weekday: 4,
          available: true,
          time_ranges: [
            { start: '08:00', end: '12:00' },
            { start: '13:00', end: '17:00' }
          ]
        },
        {
          weekday: 5,
          available: true,
          time_ranges: [
            { start: '08:00', end: '12:00' },
            { start: '13:00', end: '17:00' }
          ]
        },
        {
          weekday: 6,
          available: false,
          time_ranges: []
        },
        {
          weekday: 7,
          available: false,
          time_ranges: []
        }
      ]
    },
    service: {}
  }
};

const initialEntityState = {
  ...getInitialEntityState<Vendor>()
};
const state: EntitiesState<Vendor> & {
  form?: VendorFormData;
  smsCount?: number;
} = initialEntityState;
state.form = cloneDeep(newVendorBaseState) as VendorFormData;
state.smsCount = 0;

export const useVendorsStore = defineStore('vendors', {
  state: () => state,
  getters: {
    ...createEntityGetters<Vendor>(),
    getAllAsSelectOptions: state => {
      return state.entities.map((vendor: Vendor) => {
        return {
          label: vendor.public_name,
          value: vendor.id
        };
      });
    }
  },
  actions: {
    ...createEntityActions<Vendor>(Collection.VENDORS, initialEntityState),
    async getUserVendor() {
      const userStore = useUsersStore();
      const data = await Promise.all([
        this.findOne(userStore.loggedInVendorId),
        vendorsService.getRemainingCredits(userStore.loggedInVendorId)
      ]);
      state.smsCount = data[1];
      this.form = {
        ...this.getById(userStore.loggedInVendorId),
        publicAddressSameAsLegal: false,
        pictureFile: null,
        resources: null
      };
    },
    getNewVendor() {
      // @ts-ignore
      this.currentEntity.entity = newVendorBaseState;
    },
    async edit(vendor: Vendor | VendorFormData, validate: VendorSettingsPage) {
      this.saving = true;
      this.failure = false;
      try {
        const response = await vendorsService.edit(vendor, validate);
        this.saving = false;
        this.currentEntity = response;
        this.form = {
          ...response,
          publicAddressSameAsLegal: false,
          pictureFile: null,
          resources: null
        };
        useUiStore().alertSuccess(i18n.t('toast.changesSaved'));
      } catch (exception) {
        this.failure = true;
        this.saving = false;
        if (exception.errors !== undefined) {
          this.validationErrors = exception.errors;
        }
        useUiStore().alertValidationError(exception);
      }
    },
    async revokingSlack(vendor: VendorFormData) {
      this.saving = true;
      this.failure = false;
      try {
        const response = await vendorsService.revokingSlack(vendor);
        this.validationErrors = {};
        this.saving = false;
        this.currentEntity = response;
      } catch (exception) {
        this.failure = true;
        this.saving = false;
        if (exception.errors !== undefined) {
          this.validationErrors = exception.errors;
        }
        useUiStore().alertValidationError(exception);
      }
    },
    async getUserVendorsList(userId: number) {
      try {
        this.fetching = true;
        this.failure = false;
        this.entities = await vendorsService.userList(userId);
        this.fetching = false;
      } catch (exception) {
        this.fetching = false;
        this.failure = true;
        useUiStore().alert(exception.message);
      }
    }
  }
});
