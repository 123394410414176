




















































import { getAvailableLanguages } from '@/config/i18n.ts';
import NavbarPublic from '@/components/NavbarPublic.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { SiteSection, useUiStore } from '@/store/ui-store';
import { useUsersStore } from '@/store/users-store';
import Sidebar from '@/components/Sidebar.vue';

export default Vue.extend({
  components: { NavbarPublic, Sidebar },
  data() {
    return {
      availableLanguages: getAvailableLanguages(),
      visible: false,
      SITE_SECTION: SiteSection
    };
  },
  computed: {
    ...mapStores(useUiStore, useUsersStore),
    siteSection(): SiteSection | null {
      return this.uiStore.siteSection;
    },
    isLoggedIn(): boolean {
      return this.usersStore.isLoggedIn;
    },
    loggedInUserName(): string {
      return this.usersStore.loggedInUserName;
    },
    loggedInEmployeeId(): number | string {
      return this.usersStore.loggedInEmployeeId;
    },
    loggedInUserId(): number | string {
      return this.usersStore.loggedInUserId;
    }
  },
  created() {
    this.$router.afterEach(() => {
      this.visible = false;
      document.body.classList.remove('tw-overflow-hidden');
    });
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible;
      document.body.classList.toggle('tw-overflow-hidden');
    }
  }
});
