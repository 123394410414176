import { defineStore } from 'pinia';
import { getInitialEntityState } from '@/store/common/entities-state';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import { createEntityGetters } from '@/store/common/entities-getters';
import { Machine } from '@/model/machine';
import { InputOption } from '@/model/input-option';

const initialEntityState = {
  ...getInitialEntityState<Machine>()
};

export const useMachinesStore = defineStore('machines', {
  state: () => initialEntityState,
  getters: {
    ...createEntityGetters<Machine>(),
    getMachinesAsSelectOptions: state => (
      locationId: number | null = null,
      categoryId: number | null = null
    ): InputOption[] => {
      return state.entities
        .filter(
          machine =>
            (!locationId || machine.location_id === locationId) &&
            (!categoryId || machine.machine_model_id === categoryId)
        )
        .map(workplace => {
          return {
            label: workplace.name,
            value: workplace.id
          };
        });
    }
  },
  actions: {
    ...createEntityActions<Machine>(Collection.MACHINES, initialEntityState)
  }
});
