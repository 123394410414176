import Vue from 'vue';
import VueI18n from 'vue-i18n';
import es from '@/locales/es.json';
import fr from '@/locales/fr.json';
import en from '@/locales/en.json';

Vue.use(VueI18n);

/* require.context ne fonctionne pas avec Jest => on met manuellement ci-dessous dans "messages"
function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    '../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
} */

export function getAvailableLanguages(): string[] {
  return ['fr', 'en'];
}

export function getAvailableLanguagesOptions(): Array<{
  name: String;
  value: String;
}> {
  return [
    { name: 'Français', value: 'fr' },
    { name: 'English', value: 'en' }
  ];
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: {
    fr: fr,
    en: en,
    es: es
  }
});
