export function extract(errors) {
  const validationErrorsMessages = [];
  for (const error in errors) {
    const rules = errors[error];
    if (!isNaN(parseInt(Object.keys(rules)[0], 10))) {
      Object.entries(rules).forEach(entry => {
        validationErrorsMessages.splice(
          validationErrorsMessages.length - 1,
          0,
          `${error}_${entry[0]}: ${extract(entry[1])}`
        );
      });
    }
    for (const rule in rules) {
      if (typeof rules[rule] === 'string') {
        validationErrorsMessages.splice(
          validationErrorsMessages.length - 1,
          0,
          `${error}: ${rules[rule]}`
        );
      }
    }
  }
  return validationErrorsMessages;
}
export default {
  /**
   * Extrait les erreurs de validation de CakePHP pour les rendre lisibles dans une alerte (une ligne par erreur de validation)
   *
   * @param errors Les erreurs de validation retournées par CakePHP
   * @returns {string} Le message d'erreur
   */
  extractValidationErrors(errors) {
    const validationErrorsMessages = extract(errors);
    let message = '';
    if (validationErrorsMessages.length === 1) {
      // Une seule erreur => affichée sur une nouvelle ligne
      message += '<br>';
      message += validationErrorsMessages[0];
    } else {
      message += '<ul class="mb-0">';
      validationErrorsMessages.forEach(validationErrorMessage => {
        // Plusieurs erreurs => liste à puces
        message += '<li>' + validationErrorMessage + '</li>';
      });
      message += '</ul>';
    }
    return message;
  }
};
