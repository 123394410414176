import { Role } from '@/model/role';

const Calendar = () => import('@/components/calendar/Calendar');

const calendarRoutes = [
  {
    path: '/agenda',
    name: 'Calendar',
    component: Calendar,
    meta: {
      role: Role.STAFF,
      title: 'Agenda'
    }
  }
];

export default calendarRoutes;
