import axios from 'axios';
import i18n from '@/config/i18n';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { useUsersStore } from '@/store/users-store';

axios.interceptors.request.use(
  function(config) {
    config.headers['Accept-Language'] = i18n.locale;
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    if (response.data.token !== undefined) {
      useUsersStore().refreshToken(response.data.token);
    }
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default {
  async post(url, data) {
    try {
      const headers = getAuthHeader();
      return await axios({
        method: 'post',
        url,
        data,
        headers
      });
    } catch (exception) {
      return handleApiError(exception);
    }
  },

  async put(url, data) {
    try {
      const headers = getAuthHeader();
      headers['X-HTTP-Method-Override'] = 'PUT';
      return await axios({
        method: 'post',
        url,
        data,
        headers
      });
    } catch (exception) {
      return handleApiError(exception);
    }
  },

  async get(url, queryParams = {}) {
    try {
      return await axios({
        method: 'get',
        url,
        params: pickBy(queryParams, identity),
        headers: getAuthHeader()
      });
    } catch (exception) {
      return handleApiError(exception);
    }
  },

  async delete(url, data = {}) {
    try {
      return await axios({
        method: 'delete',
        url,
        data,
        headers: getAuthHeader()
      });
    } catch (exception) {
      return handleApiError(exception);
    }
  },

  downloadFile(url, data = {}) {
    return axios({
      method: 'post',
      responseType: 'blob',
      url,
      data,
      headers: {
        ...getAuthHeader()
      }
    });
  }
};

function handleApiError(exception) {
  if (exception.response !== undefined) {
    const usersStore = useUsersStore();
    const loggedIn = usersStore.isLoggedIn;
    if (exception.response.status === 401 && loggedIn) {
      usersStore.logout();
    } else if (exception.response.status === 401 && !loggedIn) {
      exception.response.data.data.message = '';
    }
    return Promise.reject(exception.response.data.data);
  } else {
    return Promise.reject(exception);
  }
}

export function getAuthHeader() {
  // return authorization header with jwt token
  const token = useUsersStore().user.token;
  if (token) {
    return { Authorization: 'Bearer ' + token };
  } else {
    return {};
  }
}

/**
 * A appeler avant un POST lorsqu'on envoie une image avec du contenu
 * @param dto
 */

export function buildFormData(dto) {
  const formData = new FormData();
  for (const property in dto) {
    if (typeof dto[property] === 'boolean') {
      formData.append(property, dto[property] ? '1' : '0');
    } else if (Array.isArray(dto[property])) {
      if (dto[property][0] instanceof File) {
        dto[property].forEach(file => {
          formData.append(property + '[]', file);
        });
      } else {
        formData.append(property, JSON.stringify(dto[property]));
      }
    } else if (dto[property] === null) {
      formData.append(property, '');
    } else {
      formData.append(property, dto[property]);
    }
  }

  return formData;
}
