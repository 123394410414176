import moment from 'moment';
import { API_DATETIME_FORMAT } from '@/constants';

export default {
  getBookingBodyForApi(customerId, bookingFromState) {
    const bookingBody = {
      customer_id: customerId,
      comment: bookingFromState.comment,
      events: bookingFromState.events.map(event => {
        return {
          start: moment(event.start).format(API_DATETIME_FORMAT),
          end: moment(event.end).format(API_DATETIME_FORMAT),
          customer_start: moment(event.customer_start).format(
            API_DATETIME_FORMAT
          ),
          customer_end: moment(event.customer_end).format(API_DATETIME_FORMAT),
          service_id: event.service_id,
          employee_id: event.employee_id,
          workplace_id: event.workplace_id,
          machine_id: event.machine_id,
          vendor_id: event.vendor_id,
          location_id: event.location_id,
          manual: event.manual
        };
      }),
      legal_conditions: bookingFromState.acceptedLegalConditionsIds
        ? bookingFromState.acceptedLegalConditionsIds.map(legalConditionId => {
            return {
              id: legalConditionId,
              _joinData: {
                accepted: true
              }
            };
          })
        : []
    };
    if (bookingFromState.id) {
      bookingBody.id = bookingFromState.id;
      bookingBody.events = bookingBody.events.map(event => {
        return {
          ...event,
          booking_id: bookingFromState.id
        };
      });
    }
    return bookingBody;
  }
};
