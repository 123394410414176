var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-px-4 tw-pt-3 tw-pb-3.5 tw-mb-3 tw-text-sm tw-rounded-lg",class:{
    'tw-bg-red-200': _vm.variant === _vm.AlertType.DANGER,
    'tw-bg-green-200': _vm.variant === _vm.AlertType.SUCCESS,
    'tw-bg-orange-200': _vm.variant === _vm.AlertType.WARNING,
    'tw-bg-blue-200': _vm.variant === _vm.AlertType.INFO
  }},[_c('div',{staticClass:"tw-flex tw-flex-grow tw-pr-1",class:{
      'tw-text-red-700': _vm.variant === _vm.AlertType.DANGER,
      'tw-text-green-700': _vm.variant === _vm.AlertType.SUCCESS,
      'tw-text-orange-700': _vm.variant === _vm.AlertType.WARNING,
      'tw-text-blue-700': _vm.variant === _vm.AlertType.INFO
    }},[_c('i',{staticClass:"fas tw-mr-3 tw-mt-1 tw-pt-0.5",class:{
        'fa-times-circle': _vm.variant === _vm.AlertType.DANGER,
        'fa-check-circle': _vm.variant === _vm.AlertType.SUCCESS,
        'fa-exclamation-triangle': _vm.variant === _vm.AlertType.WARNING,
        'fa-info-circle': _vm.variant === _vm.AlertType.INFO
      }}),_c('div',{staticClass:"tw-pt-0.5 tw-mt-px",class:{
        'tw-text-red-700': _vm.variant === _vm.AlertType.DANGER,
        'tw-text-green-700': _vm.variant === _vm.AlertType.SUCCESS,
        'tw-text-orange-700': _vm.variant === _vm.AlertType.WARNING,
        'tw-text-blue-700': _vm.variant === _vm.AlertType.INFO
      },domProps:{"innerHTML":_vm._s(_vm.message)}})]),(_vm.dismissible && _vm.id)?_c('div',{staticClass:"tw-p-1 tw-cursor-pointer",on:{"click":_vm.dismiss}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }