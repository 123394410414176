var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"tw-inline-flex tw-items-center tw-justify-center tw-font-semibold",class:[
    {
      'tw-px-2.5 tw-py-1.5 tw-text-xs': _vm.size === 'xs',
      'tw-px-3 tw-py-2 tw-text-sm': _vm.size === 'sm',
      'tw-px-4 tw-py-2 tw-text-md': _vm.size === 'md',
      'tw-px-4 tw-py-2 tw-text-base': _vm.size === 'lg',
      'tw-px-6 tw-py-2 tw-text-base': _vm.size === 'xl',
      'tw-px-14 lg:tw-px-10 tw-py-2 lg:tw-py-4 tw-text-base lg:tw-text-xl tw-uppercase':
        _vm.size === 'home'
    },
    _vm.buttonLink === false
      ? 'tw-bg-' +
        _vm.color +
        ' ' +
        'tw-border-2 tw-border-' +
        _vm.borderColor +
        ' ' +
        _vm.baseClasses
      : '',
    _vm.buttonLink === false ? 'hover:tw-bg-' + _vm.hoverColor : '',
    'tw-text-' + _vm.textColor,
    _vm.buttonLink === false ? 'focus:tw-ring-' + _vm.ringColor : ''
  ],style:([_vm.buttonLink ? { padding: '0px' } : '']),attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.icon && !_vm.right)?_c('i',{staticClass:"tw-mr-3",class:_vm.iconType + ' fa-' + _vm.icon}):_vm._e(),_c('span',{staticClass:"tw-leading-4"},[_vm._t("default")],2),(_vm.icon && _vm.right)?_c('i',{staticClass:"tw-ml-3",class:_vm.iconType + ' fa-' + _vm.icon}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }