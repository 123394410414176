














import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      default: false,
      type: Boolean
    },
    underlineColor: {
      default: 'primary-500',
      validator(value: any) {
        return value === 'primary-500' || value === 'yellow';
      }
    }
  }
});
