import { Role } from '@/model/role';

const Customers = () => import('@/components/customers/Customers');
const CustomerView = () => import('@/components/customers/CustomerView');
const CustomerCloseBooking = () =>
  import('@/components/customers/view/CustomerCloseBooking');
const CustomersMerge = () => import('@/components/customers/CustomersMerge');

const customersRoutes = [
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    meta: {
      role: Role.STAFF,
      title: 'Clients'
    }
  },
  {
    path: '/customers/view/:id',
    name: 'CustomersView',
    component: CustomerView,
    meta: {
      role: Role.STAFF,
      title: 'Détails client'
    }
  },
  {
    path: '/customers/close-booking/:customerId',
    name: 'CustomerCloseBooking',
    component: CustomerCloseBooking,
    meta: {
      role: Role.STAFF,
      title: 'Clôturer'
    }
  },
  {
    path: '/customers/merge',
    name: 'CustomersMerge',
    component: CustomersMerge,
    meta: {
      role: Role.STAFF
    }
  }
];

export default customersRoutes;
