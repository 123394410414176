


























































































































import Vue from 'vue';
import Weeezy from '@/components/public/Weeezy.vue';
import PlainButton from '@/components/shared/PlainButton.vue';
import HomeTitleHoverLine from '@/components/public/HomeTitleHoverLine.vue';

export default Vue.extend({
  components: {
    Weeezy,
    PlainButton,
    HomeTitleHoverLine
  }
});
