import { defineStore } from 'pinia';
import { Booking } from '@/model/booking';
import { Customer } from '@/model/customer';
import { Vendor } from '@/model/vendor';
import { Service } from '@/model/service';
import moment, { Moment } from 'moment';
import {
  API_DATE_FORMAT,
  MAXIMUM_AVAILABLE_BOOKINGS_DAYS_DISPLAYED
} from '@/constants';
import cloneDeep from 'lodash/cloneDeep';
import bookingService from '@/services/booking.service';
import * as Sentry from '@sentry/vue';
import { Location } from '@/model/location';
import { useServicesStore } from '@/store/services-store';
import orderBy from 'lodash/orderBy';
import { Event } from '@/model/event';
import { useUiStore } from '@/store/ui-store';
import { MathHelper } from '@/helpers/math-helper';
import Vue from 'vue';
import { Region } from '@/model/region';

export interface AvailableBookingsForDate {
  date: string;
  bookings: Booking[];
}

interface EditingBooking {
  bookingInEdition: Booking; // Renseigné en cas d'édition, réservation qui est éditée
  customer: Customer;
  vendor: Vendor;
  fetching: boolean;
  failure: boolean;
  saving: boolean;
  savingMessage: string;
  currentStep: number;
  location: Location; // Lieu sélectionné
  region: Region; // Région sélectionnée
  services: Service[];
  favoriteEmployeeId: number | null;
  favoriteEmployeeOnly: boolean;
  selectedBooking: Booking | null;
  selectedBookingStart: string | null;
  acceptedLegalConditionsIds: number[];
}

const initialBookingObject: EditingBooking = {
  bookingInEdition: {} as Booking, // Renseigné en cas d'édition, réservation qui est éditée
  customer: {} as Customer,
  vendor: {} as Vendor,
  fetching: false as boolean,
  failure: false as boolean,
  saving: false as boolean,
  savingMessage: '' as string,
  currentStep: 1 as number,
  location: {} as Location, // Lieu sélectionné
  region: {} as Region, // Lieu sélectionné
  services: [] as Service[],
  favoriteEmployeeId: null as number | null,
  favoriteEmployeeOnly: false as boolean,
  selectedBooking: {} as Booking | null, // Réservation(s) sélectionnée(s) (de l'API, issue de getAvailableBookings)
  selectedBookingStart: null as string | null,
  acceptedLegalConditionsIds: [] as number[]
};

export const useBookingsStore = defineStore('bookings', {
  state: () => ({
    date: null as string | null, // Jour sélectionné
    availableBookings: {
      failure: false as boolean,
      entitiesForDays: [] as AvailableBookingsForDate[]
    },
    booking: cloneDeep(initialBookingObject) as EditingBooking,
    sendBookingConfirmation: true as boolean
  }),
  getters: {
    getAvailableBookingsForDate: state => (date: string) => {
      const matchingEntityForDay = state.availableBookings.entitiesForDays.find(
        (entityForDay: AvailableBookingsForDate) => {
          return entityForDay.date === date;
        }
      );
      if (matchingEntityForDay) {
        return matchingEntityForDay.bookings;
      } else {
        return [];
      }
    },
    availableBookingsForDateWereFetched: state => (
      date: Moment | string
    ): boolean => {
      return (
        state.availableBookings.entitiesForDays.findIndex(entityForDay => {
          return entityForDay.date === moment(date).format(API_DATE_FORMAT);
        }) !== -1
      );
    }
  },
  actions: {
    setStep(stepNumber: number) {
      this.booking.currentStep = stepNumber;
    },
    nextStep() {
      this.booking.currentStep++;
    },
    previousStep() {
      this.booking.currentStep--;
      this.booking.acceptedLegalConditionsIds = [];
    },
    setBookingInEdition(booking: Booking) {
      this.booking.bookingInEdition = booking;
    },
    selectCustomer(customer: Customer) {
      this.booking.customer = customer;
    },
    selectVendorId(vendorId: number) {
      this.booking.vendor.id = vendorId;
    },
    setDate(date: string) {
      this.date = date;
      this.findAvailableBookings(this.booking.vendor.id, true);
    },
    setEmployeeId(employeeId?: number | string) {
      if (employeeId) {
        this.setEmployeeOnly(true, false);
      } else {
        this.setEmployeeOnly(false, false);
      }
      this.$patch({
        booking: {
          favoriteEmployeeId: parseInt(employeeId as string, 10),
          selectedBooking: {}
        }
      });
      if (this.date !== null) {
        this.findAvailableBookings(this.booking.vendor.id, true);
      }
    },
    setEmployeeOnly(employeeOnly: boolean, fetch: boolean) {
      this.$patch({
        booking: {
          favoriteEmployeeOnly: employeeOnly,
          selectedBooking: {}
        }
      });
      if (fetch && this.date !== null) {
        this.findAvailableBookings(this.booking.vendor.id, true);
      }
    },
    setServiceDuration(serviceToEdit: Service, duration: number) {
      Vue.set(
        this.booking,
        'services',
        this.booking.services.map(service => {
          if (service.id === serviceToEdit.id) {
            return {
              ...service,
              duration
            };
          } else {
            return service;
          }
        })
      );
      this.resetSelectedBooking();
    },
    addService(service: Service) {
      this.booking.services = orderBy(
        this.booking.services.concat(service),
        'performed_order'
      );
      this.resetSelectedBooking();
    },
    removeService(service: Service): void {
      this.booking.services = this.booking.services.filter(currentService => {
        return currentService.id !== service.id;
      });
      this.resetSelectedBooking();
    },
    setServiceHistory(
      serviceToEdit: Service,
      history: { id: number; employee_id: number; effective_duration: number }[]
    ) {
      const servicesStore = useServicesStore();
      Vue.set(
        this.booking,
        'services',
        this.booking.services.map(service => {
          let medianDurationForEmployee = servicesStore.getById(service.id)
            .duration;
          // Si un employé favori est sélectionné, on prend uniquement en compte ses propres séances de cette prestation
          if (
            this.booking.favoriteEmployeeId &&
            this.booking.favoriteEmployeeOnly
          ) {
            history = history.filter(
              event => event.employee_id === this.booking.favoriteEmployeeId
            );
          }
          if (history.length > 0) {
            medianDurationForEmployee = MathHelper.getMedian(
              history.map(history => history.effective_duration)
            );
            if (medianDurationForEmployee) {
              medianDurationForEmployee =
                Math.ceil(medianDurationForEmployee / 5) * 5;
            }
          }

          if (service.id === serviceToEdit.id) {
            return {
              ...service,
              duration: medianDurationForEmployee,
              history,
              average_duration: medianDurationForEmployee
            };
          } else {
            return service;
          }
        })
      );
    },
    resetAvailableBookings() {
      this.$patch({
        availableBookings: {
          failure: false,
          entitiesForDays: []
        }
      });
    },
    _setAvailableBookings(bookings: Booking[], date: string) {
      const matchingIndex = this.availableBookings.entitiesForDays.findIndex(
        entityForDay => {
          return entityForDay.date === date;
        }
      );
      const entityForDay = {
        date,
        bookings: bookings
      };
      if (matchingIndex >= 0) {
        // Données pour ce jour existantes, on met à jour
        this.availableBookings.entitiesForDays.splice(
          matchingIndex,
          1,
          entityForDay
        );
      } else {
        // Premier chargement des données pour ce jour
        this.availableBookings.entitiesForDays.push(entityForDay);
      }
    },
    selectBooking(booking?: Booking) {
      if (booking) {
        this.booking.selectedBooking = booking;
      } else {
        // Désélection
        this.booking.selectedBooking = null;
      }
    },
    resetSelectedBooking() {
      if (this.booking.selectedBooking?.start) {
        this.booking.selectedBookingStart = this.booking.selectedBooking.start;
      }
      this.booking.selectedBooking = null;
    },
    resetBookingAddForm() {
      this.booking = cloneDeep(initialBookingObject);
    },
    setAcceptedLegalConditions(legalConditionIds: number[]) {
      this.booking.acceptedLegalConditionsIds = legalConditionIds;
    },
    toggleAcceptedLegalCondition(legalConditionId: number) {
      const index = this.booking.acceptedLegalConditionsIds.indexOf(
        legalConditionId
      );
      if (index === -1) {
        this.booking.acceptedLegalConditionsIds.push(legalConditionId);
      } else {
        this.booking.acceptedLegalConditionsIds.splice(index, 1);
      }
    },
    setLocation(location?: Location) {
      if (location) {
        const servicesStore = useServicesStore();
        this.booking.location = location;
        this.booking.selectedBooking = null;
        this.resetAvailableBookings();
        // Nous vérifions que dans les services choisis, il n'y a pas de services non disponible dans le nouveau lieu
        const availableServicesAtLocation = servicesStore.getChosenLocationServices(
          location.id
        );
        const services: Service[] = cloneDeep(this.booking.services);
        services.forEach(selectedService => {
          const serviceIsAvailableAtLocation = availableServicesAtLocation.find(
            service => {
              return service.id === selectedService.id;
            }
          );
          if (!serviceIsAvailableAtLocation) {
            this.removeService(selectedService);
          }
        });
      } else {
        this.booking.location = {} as Location;
        this.booking.selectedBooking = null;
        const services: Service[] = cloneDeep(this.booking.services);
        services.forEach(service => {
          this.removeService(service);
        });
      }
    },
    findAvailableBookings(vendorId: number, force: boolean = false) {
      // alias
      this.fetchTimeSlotsForCurrentWeekAndNextOne(vendorId, force);
    },
    fetchTimeSlotsForCurrentWeekAndNextOne(
      vendorId: number,
      force: boolean = false
    ) {
      const firstDateOfCurrentWeekAsDate = moment(this.date);
      for (let i = 0; i < MAXIMUM_AVAILABLE_BOOKINGS_DAYS_DISPLAYED; i++) {
        const availableBookingsAlreadyFetchedForDate = this.availableBookings.entitiesForDays.find(
          entityForDay => {
            return (
              entityForDay.date ===
              firstDateOfCurrentWeekAsDate
                .clone()
                .add(i, 'days')
                .format(API_DATE_FORMAT)
            );
          }
        );
        /**
         * Si les données pour ce jour et ce groupe de soin sont déjà chargées, on ne le fait pas une 2è fois (pour éviter de spammer l'API)
         * Par contre, on peut forcer (en cas de changement d'employé p. ex.)
         */
        if (!availableBookingsAlreadyFetchedForDate || force) {
          const uiStore = useUiStore();
          this.resetAvailableBookings();
          const dateAsStringAndApiFormat = firstDateOfCurrentWeekAsDate
            .clone()
            .add(i, 'days')
            .format(API_DATE_FORMAT);
          bookingService
            .getTimeSlotsForDate(
              this.booking.location.id,
              this.booking.customer.id,
              this.booking.services,
              firstDateOfCurrentWeekAsDate
                .clone()
                .add(i, 'days')
                .format(API_DATE_FORMAT),
              this.booking.favoriteEmployeeId,
              this.booking.favoriteEmployeeOnly,
              Object.keys(this.booking.bookingInEdition).length === 0
                ? []
                : this.booking.bookingInEdition.events.map(event => {
                    return event.id;
                  }),
              vendorId
            )
            .then(bookings => {
              this._setAvailableBookings(bookings, dateAsStringAndApiFormat);
            })
            .catch(exception => {
              // eslint-disable-next-line no-console
              console.error(exception);
              Sentry.captureException(exception);
              uiStore.alert(exception.message);
              this.availableBookings.failure = true;
            });
        }
      }
    },
    setComment(comment: string) {
      if (this.booking.selectedBooking) {
        this.booking.selectedBooking.comment = comment;
      }
    },
    setSendBookingConfirmation(value: boolean) {
      this.sendBookingConfirmation = value;
    },
    async save(manual: boolean = false) {
      const uiStore = useUiStore();
      this.booking.saving = true;
      let response = {
        data: '',
        message: ''
      };
      try {
        if (Object.keys(this.booking.bookingInEdition).length === 0) {
          response = await bookingService.save(this.booking.customer.id, {
            ...this.booking.selectedBooking,
            acceptedLegalConditionsIds: this.booking.acceptedLegalConditionsIds
          });
        } else {
          this.booking.selectedBooking = {
            ...this.booking.selectedBooking,
            customer_id: this.booking.bookingInEdition.customer_id
          };
          response = await bookingService.edit(
            this.booking.bookingInEdition.id,
            this.booking.selectedBooking, // On ne peut éditer qu'une réservation à la fois,
            this.sendBookingConfirmation
          );
        }
        this.booking.saving = false;
        this.booking.savingMessage = response.message ?? '';

        return true;
      } catch (exception) {
        uiStore.alertValidationError(exception);
        this.booking.saving = false;
        return false;
      }
    }
  }
});
