































import Vue from 'vue';
import { getAvailableLanguages } from '@/config/i18n';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';

export default Vue.extend({
  data() {
    return {
      availableLanguages: getAvailableLanguages()
    };
  },
  computed: {
    ...mapStores(useUsersStore),
    language(): string {
      return this.usersStore.user.language;
    },
    isLoggedIn(): boolean {
      return this.usersStore.isLoggedIn;
    }
  },
  methods: {
    setLanguage(language) {
      this.usersStore.setLanguage(language);
    }
  }
});
