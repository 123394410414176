import apiService from './api.service';

export default {
  async sortServices(sortedServices) {
    return (
      await apiService.put(`/services/set-services-order.json`, {
        services: sortedServices
      })
    ).data.data;
  }
};
