import { Role } from '@/model/role';
const Settings = () => import('@/components/vendors/VendorSettings');
const VendorLegalInformationForm = () =>
  import('@/components/vendors/VendorLegalInformationForm');
const VendorPublicInformationForm = () =>
  import('@/components/vendors/VendorPublicInformationForm');
const VendorForm = () =>
  import('@/components/vendors/VendorBookingSettingsForm');
const VendorFidelityForm = () =>
  import('@/components/vendors/VendorFidelityForm');
const VendorIntegrationsForm = () =>
  import('@/components/vendors/VendorIntegrationsForm');

const vendorsRoutes = [
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      role: Role.ADMIN
    },
    children: [
      {
        path: 'general',
        name: 'VendorLegalInformationForm',
        component: VendorLegalInformationForm,
        meta: {
          role: Role.ADMIN
        },
        props: {
          fromVendorSignUp: false
        }
      },
      {
        path: 'public',
        name: 'VendorPublicInformationForm',
        component: VendorPublicInformationForm,
        meta: {
          role: Role.ADMIN
        },
        props: {
          withStepButtons: false
        }
      },
      {
        path: 'bookings',
        name: 'VendorForm',
        component: VendorForm,
        meta: {
          role: Role.ADMIN
        },
        props: {
          vendorSignUpFinalStep: false
        }
      },
      {
        path: 'fidelity',
        name: 'VendorFidelityForm',
        component: VendorFidelityForm,
        meta: {
          role: Role.ADMIN
        }
      },
      {
        path: 'integrations',
        name: 'VendorIntegrationsForm',
        component: VendorIntegrationsForm,
        meta: {
          role: Role.ADMIN
        }
      }
    ]
  }
];

export default vendorsRoutes;
