import { LocaleMessages } from 'vue-i18n';

export interface EntityConfigurationErrors {
  id: number;
  errors: Array<string | LocaleMessages>;
}

export declare type Comparer<T> = (a: T, b: T) => number;

export class EntitiesState<T> {
  sortFunction: Comparer<T>;
  fetching: boolean = false;
  failure: boolean = false;
  saving: boolean = false;
  validationErrors: Object = {};
  pagination: {
    page_count: number;
    current_page: number;
    has_next_page: boolean;
    has_prev_page: boolean;
    count: number;
    limit: number;
  };

  configurationErrors: EntityConfigurationErrors[] = [];

  entities: T[] = [];
  currentEntity: T = ({} as unknown) as T;

  constructor(sortFunction?: Comparer<T>) {
    this.sortFunction = sortFunction;
    this.pagination = {
      page_count: 0,
      current_page: 0,
      has_next_page: false,
      has_prev_page: false,
      count: 0,
      limit: 0
    };
  }
}

export function getInitialEntityState<T>(
  sortFunction?: Comparer<T>
): EntitiesState<T> {
  return new EntitiesState<T>(sortFunction);
}
