const colors = require('tailwindcss/colors');

module.exports = {
  prefix: 'tw-',
  purge: {
    content: ['./src/**/*.vue'],
    options: {
      safelist: {
        greedy: [
          /(primary|gray|red|yellow|home-yellow|orange|green|dark-green|blue|indigo|purple|pink|secondary|light|medium|dark|white|warm|success|transparent|danger|warning|info|warm-dark|primary-gradient|green-gradient|purple-gradient-light-to-right|purple-gradient-light-to-left)/
        ]
      }
    }
  },
  variants: {
    extend: {
      borderRadius: ['hover'],
      borderWidth: ['last'],
      backgroundColor: ['odd']
    }
  },
  theme: {
    container: theme => ({
      center: true,
      padding: {
        DEFAULT: theme('spacing.10'),
        lg: theme('spacing.14'),
        xl: theme('spacing.24')
      }
    }),
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px', // Utilisé pour ui-store::mobileLandscape()
      xl: '1280px',
      '2xl': '1536px'
    },
    extend: {
      fontSize: {
        '3xs': '.5rem',
        '2xs': '.625rem'
      },
      rotate: {
        '-22': '-22deg',
        '-135': '-135deg'
      },
      minHeight: {
        'screen-navbar': 'calc(100vh - 4rem)'
      },
      height: {
        screen: 'calc(var(--vh, 1vh) * 100)',
        'screen-navbar': 'calc(var(--vh, 1vh) * 100 - 4rem)'
      },
      spacing: {
        'full+2': 'calc(100% + 0.5rem)',
        'full-2': 'calc(100% - 0.5rem)',
        'full-3': 'calc(100% - 0.75rem)',
        'full-4': 'calc(100% - 1rem)',
        'full-8': 'calc(100% - 2rem)',
        'full-10': 'calc(100% - 2.5rem)',
        'full-12': 'calc(100% - 3rem)',
        'full-16': 'calc(100% - 4rem)',
        '3.75': '0.938rem',
        '13': '3.25rem',
        '6.5': '1.625rem',
        '112': '28rem'
      },
      boxShadow: {
        DEFAULT: '0px 1px 2px rgba(67, 84, 94, 0.12)',
        prices: '1px 1px 32px rgba(0, 0, 0, 0.25)'
      },
      zIndex: {
        1: 1,
        60: 60,
        70: 70,
        80: 80,
        90: 90,
        100: 100,
        10000: 10000
      },
      backgroundImage: {
        'primary-gradient':
          'linear-gradient(91.56deg, #9627ED 5.1%, #C357F5 96.34%)',
        'green-gradient':
          'linear-gradient(180deg, #00AC83 20.31%, #1EB490 97.92%)',
        'purple-gradient-light-to-right':
          'linear-gradient(90deg, #D98BFE, #FFFFFF)',
        'purple-gradient-light-to-left':
          'linear-gradient(270deg, #D98BFE, #FFFFFF)'
      },
      flex: {
        '80': '0 0 20rem'
      }
    },
    colors: {
      'home-yellow': '#F7C319',
      secondary: 'var(--gray-200)',
      light: 'var(--gray-100)',
      medium: 'var(--gray-600)',
      dark: 'var(--gray-900)',
      white: '#ffffff',
      warm: '#f3f0f5',
      success: colors.green,
      transparent: 'transparent',
      danger: colors.red,
      warning: colors.yellow,
      info: colors.blue,
      'warm-dark': '#32343A',
      red: {
        100: '#FFF0F0',
        200: '#FFCCCC',
        300: '#FFB2B2',
        400: '#FF8080',
        500: '#FF1919',
        600: '#E50000',
        700: '#CC0000',
        800: '#B30000',
        900: '#800000'
      },
      yellow: {
        100: '#FFFEE5',
        200: '#FFFCB7',
        300: '#FFFA8A',
        400: '#FFF85C',
        500: '#FFF72E',
        600: '#FFF500',
        700: '#D6CE00',
        800: '#ADA700',
        900: '#857F00'
      },
      orange: {
        100: '#FFF8E9',
        200: '#FFE8B7',
        300: '#FFD885',
        400: '#FFC854',
        500: '#EEAC20',
        600: '#CC900F',
        700: '#AA7403',
        800: '#885C00',
        900: '#664500'
      },
      blue: {
        100: '#EEFAFF',
        200: '#C7EFFF',
        300: '#A0E5FF',
        400: '#7ADAFF',
        500: '#52CEFD',
        600: '#3CAFDB',
        700: '#2991B9',
        800: '#1A7497',
        900: '#0E5875'
      },
      pink: {
        100: '#FFEAF3',
        200: '#FFBCD8',
        300: '#FF8EBE',
        400: '#FF5FA3',
        500: '#F32F82',
        600: '#D11B68',
        700: '#AF0B51',
        800: '#8D003C',
        900: '#6B002D'
      },
      green: {
        100: '#EAFFF8',
        200: '#C5FFEB',
        300: '#A0FFDF',
        400: '#7BFFD2',
        500: '#56FFC6',
        600: '#30FEB8',
        700: '#14D594',
        800: '#00AC72',
        900: '#008357'
      },
      purple: {
        100: '#F8F5FF',
        200: '#E9DFFF',
        300: '#DACAFF',
        400: '#CBB4FF',
        500: '#BD9EFF',
        600: '#A07FE7',
        700: '#7E5EC5',
        800: '#6142A3',
        900: '#462B81'
      },
      gray: {
        100: '#F5F5F5',
        200: '#E0E0E0',
        300: '#CCCCCC',
        400: '#B8B8B8',
        500: '#A3A3A3',
        600: '#8F8F8F',
        700: '#7A7A7A',
        800: '#666666',
        900: '#525252'
      },
      'dark-green': {
        100: '#F6FAF6',
        200: '#DDF6DC',
        300: '#C5F1C3',
        400: '#ACD8AA',
        500: '#94BE92',
        600: '#7DA57B',
        700: '#678B65',
        800: '#527251',
        900: '#3E593D'
      },
      primary: {
        100: '#FBF4FF',
        200: '#EEDCFB',
        300: '#DFBEFA',
        400: '#CB93F6',
        500: '#9627ED',
        600: '#8723D5',
        700: '#691BA6',
        800: '#5A178E',
        900: '#4B1377'
      }
    },
    fontFamily: {
      sans: ['Inter', 'Arial', 'sans-serif'],
      mono: ['Roboto Mono', 'monospace']
    }
  },
  plugins: []
};
