import Vue from 'vue';
import App from './App.vue';
import i18n from './config/i18n';
import axios from 'axios';
import filters from '@/filters/index';
import RoleAccessMixin from './mixins/RoleAccessMixin';
import '@/config/bootstrap-vue';
import '@/config/moment';
import router, { pinia } from './routes/index';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import {
  configure,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate';
import '@/directives/swipe-directive';

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API;
axios.defaults.headers.common.Accept = 'application/json';

if (process.env.VUE_APP_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn:
      'https://0418a5604ab646d68ed282b04d8d5995@o70305.ingest.sentry.io/6033520',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['api.jmservices.ch', /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

// Configuration Vee-Validate
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
const config = {
  useConstraintAttrs: false
};
configure(config);

Vue.use(filters);
Vue.mixin(RoleAccessMixin);

new Vue({
  el: '#app',
  pinia,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
