export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_TIME_FORMAT = 'HH:mm';
export const API_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export enum ConflictEventAction {
  CANCEL = 'CANCEL',
  CREATE_TIMETABLE_DATE_EXCEPTION = 'CREATE_TIMETABLE_DATE_EXCEPTION',
  CHECK_DETAILS = 'CHECK_DETAILS'
}
export enum Breakpoint {
  md = 768,
  lg = 1024,
  xl = 1280
}
export const L_PITTET_APP_ID = 3;
export const L_PITTET_MANUAL_CATEGORY_ID = 10;
export const L_PITTET_AUTOMATIC_CATEGORY_ID = 11;
export const HARMONY_VENDOR_ID = 1;
export const MAXIMUM_AVAILABLE_BOOKINGS_DAYS_DISPLAYED = 4;
export const MINIMUM_AVAILABLE_BOOKINGS_DAY_WIDTH = 100;
export const DEFAULT_TIME_SLOT = {
  start: '',
  end: '',
  own_category_id: '',
  possible_categories: [],
  duration: '',
  break: '',
  position: 1,
  location_id: ''
};
export enum FlatPickrMode {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  RANGE = 'range'
}

export enum Color {
  RED = 'red',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  BLUE = 'blue',
  PINK = 'pink',
  GREEN = 'green',
  PURPLE = 'purple',
  GRAY = 'gray',
  DARK_GREEN = 'dark-green'
}
