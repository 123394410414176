import { Role } from '../model/role';
import Home from '../components/public/Home';

const publicRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      role: Role.GUEST,
      public: true
    }
  }
];
export default publicRoutes;
