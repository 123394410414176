import { defineStore } from 'pinia';
import { AlertType, useUiStore } from '@/store/ui-store';
import { User } from '@/model/user';
import { HARMONY_VENDOR_ID, L_PITTET_APP_ID } from '@/constants';
import jwtDecode from 'jwt-decode';
import userService from '@/services/user.service';
import i18n, { getAvailableLanguages } from '@/config/i18n';
import * as Sentry from '@sentry/vue';
import moment from 'moment';
import { Role } from '@/model/role';
import { getMainCollections } from '@/store/common/utilities';
import { useVendorsStore } from '@/store/vendors-store';
import { useCalendarStore } from '@/store/calendar-store';
import { useEmployeesStore } from '@/store/employees-store';
import { useBookingsStore } from '@/store/bookings-store';
import { LoginDto } from '@/dto/login.dto';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';

const storeId = 'users';

let initialState = {
  user: {
    fetching: false as boolean,
    failure: false as boolean,
    saving: false as boolean,
    entity: {} as User,
    loggingIn: false as boolean,
    token: null as string | null,
    validationErrors: {},
    language: 'fr' as string
  }
};
const initialStateClone = cloneDeep(initialState);
if (localStorage.getItem(storeId)) {
  initialState = JSON.parse(localStorage.getItem(storeId));
}

export const useUsersStore = defineStore(storeId, {
  state: () => ({
    ...initialState
  }),
  getters: {
    isLoggedIn(): boolean {
      return !!this.user.token;
    },
    loggedInUserName(): string | null {
      return this.user.token !== null ? jwtDecode(this.user.token).name : null;
    },
    loggedInUserRole(): Role | null {
      return this.user.token !== null
        ? parseInt(jwtDecode(this.user.token).role)
        : null;
    },
    loggedInUserNotValidatedEmail(): boolean {
      return this.user.token !== null
        ? !!jwtDecode(this.user.token).notValidated
        : true;
    },
    loggedInEmployeeId(): number | null {
      return this.user.token !== null && jwtDecode(this.user.token).employeeId
        ? jwtDecode(this.user.token).employeeId
        : null;
    },
    loggedInUserId(): number | null {
      return this.user.token !== null && jwtDecode(this.user.token).sub
        ? jwtDecode(this.user.token).sub
        : null;
    },
    loggedInVendorId(): number | null {
      return this.user.token !== null && jwtDecode(this.user.token).vendorId
        ? jwtDecode(this.user.token).vendorId
        : null;
    },
    isFromLPittet(): boolean {
      return this.user.token !== null && jwtDecode(this.user.token).appId
        ? jwtDecode(this.user.token).appId === L_PITTET_APP_ID
        : false;
    },
    isFromHarmony(): boolean {
      return this.user.token !== null && jwtDecode(this.user.token).vendorId
        ? jwtDecode(this.user.token).vendorId === HARMONY_VENDOR_ID
        : false;
    },
    isAdmin(): boolean {
      return (
        this.user.token !== null &&
        jwtDecode(this.user.token).role === Role.ADMIN
      );
    },
    isStaffOrMore(): boolean {
      return (
        this.user.token !== null &&
        jwtDecode(this.user.token).role >= Role.STAFF
      );
    },
    isStaff(): boolean {
      return (
        this.user.token !== null &&
        jwtDecode(this.user.token).role === Role.STAFF
      );
    },
    isCustomerOrMore(): boolean {
      return (
        this.user.token !== null &&
        jwtDecode(this.user.token).role >= Role.CUSTOMER
      );
    },
    isCustomer(): boolean {
      return (
        this.user.token !== null &&
        jwtDecode(this.user.token).role === Role.CUSTOMER
      );
    },
    isGuest(): boolean {
      return this.user.token === null;
    }
  },
  actions: {
    async getUser(userId: number) {
      const uiStore = useUiStore();
      try {
        this.user.fetching = true;
        const user: User = await userService.getById(userId);
        this.user.fetching = false;
        this.user.entity = user;

        return user;
      } catch (exception) {
        this.user.failure = true;
        uiStore.alert(exception.message);
      }
    },
    async edit(user: Partial<User>) {
      const uiStore = useUiStore();
      try {
        this.user.failure = false;
        this.user.saving = true;
        const userEdited: User = await userService.edit(user);
        this.user.entity = Object.assign(this.user.entity, userEdited);
        this.user.saving = false;
        uiStore.alert(i18n.t('myAccountEdit.success'), AlertType.SUCCESS);

        return true;
      } catch (exception) {
        this.user.failure = true;
        this.user.saving = false;
        if (exception.errors !== undefined) {
          this.user.validationErrors = exception.errors;
        } else {
          this.user.validationErrors = {};
        }
        uiStore.alertValidationError(exception);
        return false;
      }
    },
    async signUp(userData: Partial<User>) {
      const uiStore = useUiStore();
      try {
        this.user.saving = true;
        const messageAndToken = await userService.signUp(userData);
        this.user.saving = false;
        this.user.token = messageAndToken.token;
        uiStore.alert(messageAndToken.message, AlertType.SUCCESS);

        return true;
      } catch (exception) {
        this.user.saving = false;
        if (exception.errors !== undefined) {
          this.user.validationErrors = exception.errors;
        }
        uiStore.alertValidationError(exception);

        return false;
      }
    },
    async emailValidation(id: number | string, token: string) {
      const uiStore = useUiStore();
      try {
        const messageAndToken = await userService.validateEmail({ id, token });
        this.user.token = messageAndToken.token;
        uiStore.alert(messageAndToken.message, AlertType.SUCCESS, 15);
      } catch (exception) {
        uiStore.alert(exception.message);
      }
    },
    async login(user: LoginDto) {
      const uiStore = useUiStore();
      const vendorsStore = useVendorsStore();
      this.user.loggingIn = true;
      this.user.failure = false;
      try {
        this.user.token = await userService.login(user);
        this.user.loggingIn = false;
        if (this.isStaffOrMore) {
          vendorsStore.getById(this.loggedInVendorId as number);
        }
        Sentry.setUser({ username: user.usernameOrEmail });
      } catch (exception) {
        this.user.loggingIn = false;
        this.user.token = null;
        this.user.failure = true;
        uiStore.alert(exception.message);
      }
    },
    refreshToken(token: string) {
      this.user.token = token;
    },
    reset() {
      Vue.set(this, '$state', cloneDeep(initialStateClone));
    },
    logout() {
      this.reset();
      const calendarStore = useCalendarStore();
      calendarStore.reset();
      const employeesStore = useEmployeesStore();
      employeesStore.reset();
      const bookingsStore = useBookingsStore();
      bookingsStore.$reset();
      localStorage.clear();
      // La redirection est gérée dans App.vue (car pas possible d'importer router ici)
      Sentry.configureScope(scope => scope.setUser(null));
    },
    async forgottenPassword(user: any) {
      const uiStore = useUiStore();
      try {
        await userService.forgottenPassword(user);
        return true;
      } catch (exception) {
        uiStore.alert(exception.message);
        return false;
      }
    },
    async resetPassword(user: any) {
      const uiStore = useUiStore();
      try {
        const data = await userService.resetPassword(user);
        uiStore.alert(data.message, AlertType.SUCCESS);
        return true;
      } catch (exception) {
        uiStore.alert(exception.message);
        return false;
      }
    },
    /**
     * Charge toutes les collections, les données du vendor et définit l'employé par défaut
     */
    loadDataForStaffUser() {
      const vendorsStore = useVendorsStore();
      if (this.isStaffOrMore) {
        // On définit l'employé par défaut
        const employeesStore = useEmployeesStore();
        const usersStore = useUsersStore();
        const vendorsUnsubscribe = vendorsStore.$onAction(({ name, after }) => {
          if (name === 'findOne') {
            vendorsUnsubscribe();
            after(() => {
              vendorsStore.setCurrentEntity(
                vendorsStore.getById(this.loggedInVendorId as number)
              );
            });
          }
        });
        const unsubscribe = employeesStore.$onAction(({ name, after }) => {
          if (name === 'findAll') {
            unsubscribe();
            after(() => {
              if (this.isStaff) {
                employeesStore.setCurrentEntity(
                  employeesStore.getById(usersStore.loggedInEmployeeId)
                );
              } else if (this.isAdmin) {
                if (!employeesStore.currentEntity) {
                  if (
                    find(employeesStore.getAllWithTimetablesEnabled(), {
                      id: usersStore.loggedInEmployeeId
                    })
                  ) {
                    employeesStore.setCurrentEntity(
                      employeesStore.getById(usersStore.loggedInEmployeeId)
                    );
                  } else {
                    employeesStore.setCurrentEntity(
                      employeesStore.getAllWithTimetablesEnabled()[0]
                    );
                  }
                }
              }
            });
          }
        });
        getMainCollections(this.loggedInVendorId as number, true);
      }
    },
    async setLanguage(language: string) {
      const uiStore = useUiStore();
      if (getAvailableLanguages().indexOf(language) >= 0) {
        i18n.locale = language;
        moment.updateLocale(language, { week: { dow: 1 } });
        this.user.language = language;
        if (this.isLoggedIn) {
          if (this.isStaffOrMore) {
            this.loadDataForStaffUser();
          }
          try {
            await userService.changeAccountLanguage(language); // Pas besoin de redonner l'info ou de faire patienter l'utilisateur
          } catch (exception) {
            uiStore.alert(exception.message);
          }
        }
      }
    }
  }
});
