import apiService from '@/services/api.service';

export default {
  async findBetweenDates(
    dateFrom,
    dateTo = null,
    employeeId = null,
    machineId = null,
    workplaceId = null
  ) {
    return (
      await apiService.get(`events/between-dates/${dateFrom}.json`, {
        date_to: dateTo,
        employee_id: employeeId,
        machine_id: machineId,
        workplace_id: workplaceId
      })
    ).data.data;
  }
};
