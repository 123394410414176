


















































































import Vue from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { CalendarResourceType } from '@/store/calendar-store';
import CurrentUser from '@/components/auth/CurrentUser.vue';

export default Vue.extend({
  components: {
    CurrentUser
  },
  data() {
    return {
      CalendarResourceType: CalendarResourceType
    };
  },
  computed: {
    ...mapStores(useUsersStore),
    isLoggedIn(): boolean {
      return this.usersStore.isLoggedIn;
    }
  }
});
