import apiService from '@/services/api.service';

export default {
  async signUp(userData) {
    const response = await apiService.post('/users/register.json', userData);
    return response.data.data;
  },
  async getById(userId) {
    const response = await apiService.get(`/users/${userId}.json`);
    return response.data.data;
  },
  async edit(user) {
    const response = await apiService.put(`/users/${user.id}.json`, user);
    return response.data.data;
  },
  async login(user) {
    const response = await apiService.post('/users/token.json', user);
    return response.data.data;
  },
  async forgottenPassword(user) {
    const response = await apiService.post(
      '/users/forgottenPassword.json',
      user
    );
    return response.data.data;
  },
  async resetPassword(user) {
    const response = await apiService.post('/users/resetPassword.json', user);
    return response.data.data;
  },
  async sendValidationEmail(email) {
    const response = await apiService.post('/users/new-email-validation.json', {
      email
    });
    return response.data.data;
  },
  async validateEmail(validationData) {
    const response = await apiService.post(
      '/users/emailValidation.json',
      validationData
    );
    return response.data.data;
  },
  async changeAccountLanguage(language) {
    const response = await apiService.post('users/locale/' + language);
    return response.data.data;
  },

  async checkEmail(query) {
    let queryText = '';
    Object.keys(query).forEach((q, index) => {
      if (query[q] && query[q] !== '') {
        if (index > 0) {
          queryText += '&';
        }
        queryText += `${q}=${query[q]}`;
      }
    });
    return (await apiService.get('users/check-email.json?' + queryText)).data
      .data;
  }
};
