









import Vue from 'vue';
import { mapStores } from 'pinia';
import { useUiStore } from '@/store/ui-store';

export default Vue.extend({
  computed: {
    ...mapStores(useUiStore),
    reducedSidebar(): boolean {
      return this.uiStore.reducedSidebar;
    }
  }
});
