import { defineStore } from 'pinia';
import {
  EntityConfigurationErrors,
  getInitialEntityState
} from '@/store/common/entities-state';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import { createEntityGetters } from '@/store/common/entities-getters';
import { Workplace } from '@/model/workplace';
import { useLocationsStore } from '@/store/locations-store';
import { Location } from '@/model/location';
import { InputOption } from '@/model/input-option';
import i18n from '@/config/i18n';

const initialEntityState = {
  ...getInitialEntityState<Workplace>()
};

export const useWorkplacesStore = defineStore('workplaces', {
  state: () => initialEntityState,
  getters: {
    ...createEntityGetters<Workplace>(),
    getForLocationId: state => (locationId: number) => {
      return state.entities.filter(workplace => {
        return workplace.location.id === locationId;
      });
    },
    getWorkplacesAsSelectOptions: state => (
      locationId: number | null = null,
      workplaceIds: number[] | null = null
    ): InputOption[] => {
      const locationsStore = useLocationsStore();
      if (locationId) {
        return (locationsStore.entities.find(
          location => location.id === locationId
        ) as Location).workplaces
          .filter(
            workplace => !workplaceIds || workplaceIds.includes(workplace.id)
          )
          .map(workplace => {
            return {
              label: workplace.name,
              value: workplace.id
            };
          });
      } else {
        let workplacesOptions: InputOption[] = [];

        locationsStore.entities.forEach((location: Location) => {
          workplacesOptions = workplacesOptions.concat(
            location.workplaces
              .filter(
                workplace =>
                  !workplaceIds || workplaceIds.includes(workplace.id)
              )
              .map(workplace => {
                return {
                  label: location.short_name + ' - ' + workplace.name,
                  value: workplace.id
                };
              })
          );
        });

        return workplacesOptions;
      }
    }
  },
  actions: {
    ...createEntityActions<Workplace>(
      Collection.WORKPLACES,
      initialEntityState
    ),
    verifyConfiguration() {
      const workplacesErrors: EntityConfigurationErrors[] = [];
      this.entities.forEach((workplace: Workplace) => {
        const entityErrors: EntityConfigurationErrors = {
          id: workplace.id,
          errors: []
        };
        if (workplace.employees.length === 0) {
          entityErrors.errors.push(i18n.t('configError.workplaceEmployees'));
        }
        if (workplace.services.length === 0) {
          entityErrors.errors.push(i18n.t('configError.workplaceServices'));
        }
        if (entityErrors.errors.length > 0) {
          workplacesErrors.push(entityErrors);
        }
      });
      this.setConfigurationErrors(workplacesErrors);
    }
  }
});
