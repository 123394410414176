import apiService from '@/services/api.service';
import { camelCaseToSlug } from '@/helpers/collection-name.helper';

export default {
  async findAll(collectionName, vendorId = null) {
    const url = !vendorId
      ? `/${camelCaseToSlug(collectionName)}/all.json`
      : `/${camelCaseToSlug(collectionName)}/all/${vendorId}.json`;
    return (await apiService.get(url)).data.data;
  },
  async findAllPaginated(collectionName, options = { limit: 25, page: 1 }) {
    return (
      await apiService.get(`/${camelCaseToSlug(collectionName)}.json`, options)
    ).data;
  },
  async findOne(collectionName, entityId) {
    return (
      await apiService.get(
        `${camelCaseToSlug(collectionName)}/${entityId}.json`
      )
    ).data.data;
  },
  async add(collectionName, entity) {
    return (
      await apiService.post(`/${camelCaseToSlug(collectionName)}.json`, entity)
    ).data.data;
  },
  async edit(collectionName, entity) {
    return (
      await apiService.put(
        `/${camelCaseToSlug(collectionName)}/${entity.id}.json`,
        entity
      )
    ).data.data;
  },
  async delete(collectionName, entity) {
    return (
      await apiService.delete(
        `/${camelCaseToSlug(collectionName)}/${entity.id}.json`,
        {
          id: entity.id
        }
      )
    ).data.data;
  },
  async verifyDelete(collectionName, entity) {
    return (
      await apiService.post(
        `/${camelCaseToSlug(collectionName)}/verify-delete/${entity.id}.json`
      )
    ).data.data;
  },
  async setRelations(
    currentCollection,
    currentEntityId,
    relationCollection,
    payload
  ) {
    return (
      await apiService.post(
        `/${camelCaseToSlug(currentCollection)}/set-${camelCaseToSlug(
          relationCollection
        )}/${currentEntityId}.json`,
        payload
      )
    ).data.data;
  }
};
