import Vue from 'vue';
import Hammer from 'hammerjs';
import { DirectiveBinding } from 'vue/types/options';

Vue.directive('swipe', {
  bind: function(el: HTMLElement, binding: DirectiveBinding) {
    if (typeof binding.value === 'function') {
      const hammerManager = new Hammer(el);
      hammerManager
        .get('swipe')
        .set({ direction: Hammer.DIRECTION_HORIZONTAL });
      hammerManager.on('swipe', binding.value);
    }
  }
});
