import { defineStore } from 'pinia';
import {
  EntityConfigurationErrors,
  getInitialEntityState
} from '@/store/common/entities-state';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import { createEntityGetters } from '@/store/common/entities-getters';
import { Region } from '@/model/region';
import i18n from '@/config/i18n';

const initialEntityState = {
  ...getInitialEntityState<Region>()
};

export const useRegionsStore = defineStore('regions', {
  state: () => initialEntityState,
  getters: {
    ...createEntityGetters<Region>()
  },
  actions: {
    ...createEntityActions<Region>(Collection.REGIONS, initialEntityState),
    verifyConfiguration() {
      const regionsErrors: EntityConfigurationErrors[] = [];
      this.entities.forEach((region: Region) => {
        const entityErrors: EntityConfigurationErrors = {
          id: region.id,
          errors: []
        };
        if (region.locations?.length === 0) {
          entityErrors.errors.push(i18n.t('configError.regionLocations'));
        }
        if (entityErrors.errors.length > 0) {
          regionsErrors.push(entityErrors);
        }
      });

      this.setConfigurationErrors(regionsErrors);
    }
  }
});
