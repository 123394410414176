import { EntitiesState } from '@/store/common/entities-state';
import { Entity } from '@/model/entity';

export interface EntitiesGetters<T> {
  getById(state: EntitiesState<T>): (id: number | string) => T | undefined;
  getConfigurationErrors(state: EntitiesState<T>): (entityId: number) => string;
  hasConfigurationErrors(
    state: EntitiesState<T>
  ): (entityId: number | null) => boolean;
}

export function createEntityGetters<T>(): EntitiesGetters<T> {
  return {
    getById: (state: EntitiesState<T>) => {
      return (id: number | string) => {
        return state.entities.find(entity => {
          return ((entity as unknown) as Entity).id === parseInt(id as string);
        });
      };
    },
    getConfigurationErrors: (state: EntitiesState<T>) => {
      return (entityId: number): string => {
        const entityErrors = state.configurationErrors.find(configError => {
          return configError.id === entityId;
        });
        if (entityErrors) {
          return entityErrors.errors.join('<br><br>');
        }
        return '';
      };
    },
    hasConfigurationErrors: (state: EntitiesState<T>) => {
      return (entityId: number | null): boolean => {
        if (!entityId) {
          return state.configurationErrors?.length > 0;
        } else {
          const entityErrors = state.configurationErrors.find(configError => {
            return configError.id === entityId;
          });

          return typeof entityErrors !== 'undefined';
        }
      };
    }
  };
}
