/** @deprecated **/
export const COLLECTION = Object.freeze({
  SERVICES: 'services',
  CHILD_SERVICES: 'childServices',
  WORKPLACES: 'workplaces',
  MACHINES: 'machines',
  MACHINE_MODELS: 'machineModels',
  CATEGORIES: 'categories',
  LOCATIONS: 'locations',
  EMPLOYEES: 'employees',
  CLOSED_DATES: 'closedDates',
  PUBLIC_LOCATIONS: 'locations',
  REGIONS: 'regions'
});

export enum Collection {
  SERVICES = 'services',
  CHILD_SERVICES = 'childServices',
  WORKPLACES = 'workplaces',
  MACHINES = 'machines',
  MACHINE_MODELS = 'machineModels',
  CATEGORIES = 'categories',
  LOCATIONS = 'locations',
  EMPLOYEES = 'employees',
  CLOSED_DATES = 'closedDates',
  PUBLIC_LOCATIONS = 'locations',
  REGIONS = 'regions',
  VENDORS = 'vendors',
  TIMETABLE_DATE_EXCEPTIONS = 'timetableDateExceptions',
  TIMETABLES = 'timetables',
  VOUCHERS = 'vouchers',
  CUSTOMERS = 'customers'
}
